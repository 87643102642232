import { type ChangeEvent, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper,
    Button,
    Box,
    Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { type PortedStudentNetworkEvent, StudentNetworkEventCSVHeaders } from '../AdminCohortEventsPorter.types';
import { renderCellContent } from './ReviewRecordsHelpers';

interface ReviewTableProps {
    editedRecords: PortedStudentNetworkEvent[];
    handleTitleChange: (index: number) => (event: ChangeEvent<HTMLInputElement>) => void;
    handleDateChange: (index: number, field: 'start_time' | 'end_time') => (newValue: moment.Moment | null) => void;
    handlePublishedChange: (index: number) => (event: ChangeEvent<HTMLInputElement>) => void;
    hasChanges: boolean;
    resetChanges: () => void;
}

const ReviewTable: React.FC<ReviewTableProps> = ({
    editedRecords,
    handleTitleChange,
    handleDateChange,
    handlePublishedChange,
    hasChanges,
    resetChanges,
}) => {
    const [expandedTable, setExpandedTable] = useState(false);

    const columns = expandedTable
        ? (Object.keys(StudentNetworkEventCSVHeaders).filter(
              header =>
                  ![
                      'id',
                      'selectedCohort',
                      'otherTargetedCohorts',
                      'cohortStartDate',
                      'cohortStartToEventStart',
                  ].includes(header),
          ) as (keyof typeof StudentNetworkEventCSVHeaders)[])
        : ['title', 'event_type', 'start_time', 'end_time', 'location_name', 'published'];

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Paper elevation={0} sx={{ mb: 3, p: 2, backgroundColor: 'grey.100', borderRadius: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="subtitle2">Total rows: {editedRecords.length}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {hasChanges && (
                            <Button size="small" onClick={resetChanges} sx={{ mr: 2 }}>
                                Reset Changes
                            </Button>
                        )}
                        <Typography variant="body2" color="text.secondary" sx={{ mr: 1, fontSize: '0.75rem' }}>
                            Showing {expandedTable ? 'all' : 'important'} columns
                        </Typography>
                        <Button
                            size="small"
                            onClick={() => setExpandedTable(!expandedTable)}
                            endIcon={expandedTable ? <ExpandLess /> : <ExpandMore />}
                        >
                            {expandedTable ? 'Show fewer' : 'Show all'}
                        </Button>
                    </Box>
                </Box>
            </Paper>

            <TableContainer
                component={Paper}
                elevation={0}
                sx={{
                    maxHeight: 'calc(100vh - 300px)',
                    mb: 3,
                    border: '1px solid rgba(224, 224, 224, 1)',
                }}
            >
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map(header => (
                                <TableCell
                                    key={header}
                                    sx={{
                                        p: 1,
                                        fontSize: '0.8rem',
                                        fontWeight: 'bold',
                                        whiteSpace: 'nowrap',
                                        backgroundColor: 'grey.100',
                                    }}
                                >
                                    {
                                        StudentNetworkEventCSVHeaders[
                                            header as keyof typeof StudentNetworkEventCSVHeaders
                                        ]
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {editedRecords.map((row, index) => (
                            <TableRow key={`row-${row.id || index}`}>
                                {columns.map(column => (
                                    <TableCell
                                        key={`${row.id || index}-${column}`}
                                        sx={{
                                            p: 1,
                                            fontSize: '0.75rem',
                                            minWidth: column === 'title' ? 200 : 'auto',
                                        }}
                                    >
                                        {renderCellContent(
                                            row,
                                            column,
                                            editedRecords,
                                            handleTitleChange,
                                            handleDateChange,
                                            handlePublishedChange,
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </LocalizationProvider>
    );
};

export default ReviewTable;

import { useRef, type ReactElement } from 'react';
import AdminTable, { TimeCellConfig } from 'AdminTable';
import { angular2react } from 'angular2react';
import adminEditGroupComponent from 'AdminGroups/angularModule/scripts/admin_edit_group_component';
import { type Column } from 'react-table';
import { type Group } from '@Types';
import { useAngularContext } from 'AngularContext';

export default function AdminGroups(): ReactElement {
    const $injector = useAngularContext();
    const Group = $injector.get<Group>('Group');

    // You probably don't want to use this as a model. See the discussion of angularDirectiveToReact
    // in docs/angular-react-integration.md
    const AdminEditGroup = angular2react('AdminEditGroupComponent', adminEditGroupComponent, $injector);

    const columns: Column<Record<string, unknown>>[] = useRef([
        { Header: 'Name', accessor: 'name' },
        { Header: 'Created', accessor: 'created_at', ...TimeCellConfig },
        { Header: 'Modified', accessor: 'updated_at', ...TimeCellConfig },
    ]).current;

    const fetchData = useRef(() => Group.index()).current;
    const getBlankRecord = useRef(() => Group.new()).current;

    return (
        <div className="admin-groups">
            <AdminTable
                idParam="group-id"
                klassName="Group"
                columns={columns}
                fetchData={fetchData}
                filterFields={[]}
                EditComponent={AdminEditGroup}
                getBlankRecord={getBlankRecord}
                showCSVExport
            />
        </div>
    );
}

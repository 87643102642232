import { type SubStatusKey, type StatusKey, type TopLevelStatusKey } from './AdminUsers.types';

// See also STATUS_MAP in users_index_mixin.rb
export const StatusOptions = [
    { id: 'not_applied', label: 'Not Applied', children: [] } as const,
    {
        id: 'applied',
        label: 'Applied, Awaiting Decision',
        children: [
            { id: 'not_reviewed', label: 'Not Reviewed' },
            { id: 'not_offered_interview', label: 'Not Offered Interview' },
            { id: 'invited_to_interview', label: 'Invited to Interview' },
            { id: 'interview_scheduled', label: 'Interview Scheduled' },
            { id: 'interview_conducted', label: 'Interview Conducted' },
        ],
    } as const,
    { id: 'not_offered_admission', label: 'Not Offered Admission', children: [] },
    { id: 'offered_admission', label: 'Offered Admission, Awaiting Registration', children: [] },
    { id: 'offer_expired', label: 'Offer Expired', children: [] },
    {
        id: 'registered',
        label: 'Registered, Awaiting Program Start',
        children: [
            { id: 'registered_not_yet_enrolled', label: 'Not Yet Enrolled' },
            { id: 'registered_enrolled', label: 'Enrolled' },
        ],
    },
    {
        id: 'matriculated',
        label: 'Matriculated',
        children: [
            { id: 'matriculated_not_yet_enrolled', label: 'Not Yet Enrolled' },
            { id: 'matriculated_enrolled', label: 'Enrolled' },
            { id: 'academic_hold', label: 'Academic Hold' },
            { id: 'academic_probation', label: 'Academic Probation' },
            { id: 'behind_on_payments', label: 'Behind on Payments' },
        ],
    },
    {
        id: 'graduated',
        label: 'Graduated',
        children: [
            { id: 'with_honors', label: 'With Honors' },
            { id: 'without_honors', label: 'Without Honors' },
        ],
    },
    {
        id: 'did_not_graduate',
        label: 'Did Not Graduate',
        children: [
            { id: 'withdrawn', label: 'Withdrawn' },
            { id: 'expelled', label: 'Expelled' },
            { id: 'failed', label: 'Failed' },
        ],
    },
] as const;

export const StatusOptionsById = StatusOptions.reduce(
    (prev, curr) => ({ ...prev, [curr.id]: curr }),
    {} as { [T in typeof StatusOptions[number] as T['id']]: T },
);

export const statusOptionsWithChildren = (topLevelStatuses: TopLevelStatusKey[]) => {
    const childrenStatuses = topLevelStatuses.reduce<StatusKey[]>(
        (acc: StatusKey[], e: TopLevelStatusKey) => acc.concat(StatusOptionsById[e].children.map(c => c.id)),
        [],
    );
    const statuses: StatusKey[] = [];
    return statuses.concat(topLevelStatuses, childrenStatuses);
};

export const statusOptionWithParent = (subStatus: SubStatusKey) => {
    const topLevelStatusOption = StatusOptions.find(s => s.children.map(c => c.id).includes(subStatus));
    if (!topLevelStatusOption) throw new Error('Parent status not found');
    return [topLevelStatusOption.id, subStatus];
};

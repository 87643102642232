import { formattedUserFacingDateTime } from 'DateHelpers';

export default function TimeCell({ cell: { value } }) {
    return <>{formattedUserFacingDateTime(1000 * value)}</>;
}

export const TimeCellConfig = {
    Cell: TimeCell,
    CsvCell: 'time',
};

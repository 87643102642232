import moment from 'moment';
import { type CohortForAdminListsAttrs } from 'Cohorts';
import { Checkbox, Chip, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { type PortedStudentNetworkEvent, type ImportData } from '../AdminCohortEventsPorter.types';

export const targetStudentStatusOptions = [
    { key: 'prospect', label: 'Prospective Students' },
    { key: 'included_and_activated', label: 'Registered Students (before cohort start)' },
    { key: 'current', label: 'Current Students' },
    { key: 'graduated', label: 'Alumni' },
];

export const handleTitleChange =
    (editedRecords: PortedStudentNetworkEvent[], index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedRecords = [...editedRecords];
        updatedRecords[index].title = event.target.value;
        return updatedRecords;
    };

export const handleDateChange =
    (editedRecords: PortedStudentNetworkEvent[], index: number, field: 'start_time' | 'end_time') =>
    (newValue: moment.Moment | null) => {
        if (newValue && moment.isMoment(newValue)) {
            const updatedRecords = [...editedRecords];
            const currentTime = moment();

            if (field === 'start_time' && newValue.isBefore(currentTime)) {
                return { error: 'Start time cannot be in the past', records: editedRecords };
            }

            if (field === 'end_time') {
                const startTime = moment.unix(updatedRecords[index].start_time as number);
                if (newValue.isBefore(startTime)) {
                    return { error: 'End time cannot be before start time', records: editedRecords };
                }
            }

            updatedRecords[index][field] = newValue.unix();
            return { error: null, records: updatedRecords };
        }
        return { error: null, records: editedRecords };
    };

export const handlePublishedChange =
    (editedRecords: PortedStudentNetworkEvent[], index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedRecords = [...editedRecords];
        updatedRecords[index].published = event.target.checked;
        return updatedRecords;
    };

export const prepareImportData = (
    editedRecords: PortedStudentNetworkEvent[],
    importOption: 'as-is' | 'override',
    selectedCohorts: CohortForAdminListsAttrs[],
    targetStudentStatuses: { key: string; label: string }[],
): ImportData => ({
    recordsToImport: editedRecords,
    importOption,
    selectedCohorts,
    targetStudentStatuses: targetStudentStatuses.map(status => status.key),
});

export const renderCellContent = (
    row: PortedStudentNetworkEvent,
    column: string,
    editedRecords: PortedStudentNetworkEvent[],
    handleTitleChangeWrapper: (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleDateChangeWrapper: (
        index: number,
        field: 'start_time' | 'end_time',
    ) => (newValue: moment.Moment | null) => void,
    handlePublishedChangeWrapper: (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => void,
) => {
    switch (column) {
        case 'title':
            return (
                <TextField
                    value={row.title}
                    onChange={handleTitleChangeWrapper(editedRecords.indexOf(row))}
                    size="small"
                    fullWidth
                    multiline
                    rows={2}
                    inputProps={{ style: { fontSize: '0.75rem' } }}
                />
            );
        case 'event_type':
            return <Chip label={row.event_type} size="small" color="primary" variant="outlined" />;
        case 'start_time':
        case 'end_time':
            return (
                <DateTimePicker
                    value={moment.unix(row[column] as number)}
                    onChange={handleDateChangeWrapper(editedRecords.indexOf(row), column as 'start_time' | 'end_time')}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size="small"
                            sx={{
                                '& input': { fontSize: '0.75rem', minWidth: 'max-content' },
                            }}
                        />
                    )}
                />
            );
        case 'published':
            return (
                <Checkbox
                    checked={row.published}
                    onChange={handlePublishedChangeWrapper(editedRecords.indexOf(row))}
                    sx={{
                        '& .MuiSvgIcon-root': { fontSize: '1rem' },
                    }}
                />
            );
        case 'place_details':
            return <Typography variant="caption">{JSON.stringify(row.place_details)}</Typography>;
        default:
            return (
                <Typography variant="caption">{row[column as keyof PortedStudentNetworkEvent]?.toString()}</Typography>
            );
    }
};

export const getTargetingOptions = [
    {
        value: 'as-is',
        label: 'Use CSV targeting',
        icon: 'ArticleOutlined',
        explanation: 'Existing targeting from the CSV will be used, including targeted institutions and program types.',
    },
    {
        value: 'override',
        label: 'Override targeting',
        icon: 'CrisisAlert',
        explanation:
            'Select specific cohorts and student statuses to target. Existing targeting from the CSV will be ignored.',
    },
];

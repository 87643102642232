/* eslint-disable no-nested-ternary */
import { memo, useEffect, useMemo, useState } from 'react';
import { type Path, useFormContext } from 'FrontRoyalReactHookForm';
import { Chip } from '@mui/material';
import { Select, type TaggedSelectOptionTag } from 'FrontRoyalMaterialUiForm';
import { type CohortForAdminListsAttrs } from 'Cohorts';
import { CohortSelectOption } from 'CohortFormHelper';
import { type Moment } from 'moment-timezone';
import { type StudentStatus } from 'StudentNetworkEvent';
import {
    type InstitutionEntry,
    type EditStudentNetworkFormValues,
} from 'AdminEditStudentNetworkEvent/AdminEditStudentNetworkEvent.types';
import './TargetCohortSelect.scss';
import { type AnyObject } from '@Types';

type Props = {
    allCohortsForInstitution: CohortForAdminListsAttrs[];
    institutionEntry: InstitutionEntry;
    name: Path<EditStudentNetworkFormValues>;
};

// If a time is added to the event and then date_tbd is checked, we don't immediately remove
// start_time from the form properties (maybe we should?). In that case, we should behave as
// though there is no start time (that is, we should show all cohort options regardless of the times
// on the cohorts)
function useEventStart() {
    const { watch } = useFormContext<EditStudentNetworkFormValues>();
    const { start_time, date_tbd } = watch();
    if (date_tbd) {
        return null;
    }
    return start_time;
}

function renderValue(selectedCohortIds: string[]) {
    const cohortCount = selectedCohortIds.length;
    if (cohortCount === 1) {
        return '1 cohort';
    }

    if (cohortCount >= 2) {
        return `${cohortCount} cohorts`;
    }

    return '';
}

// This is a memoizable function that returns a new render function whenever the targetStudentStatuses,
// eventStart, or dateTbd changes. The returned function takes a cohort as an argument and returns a component
// that renders an option for that cohort, given the current  targetStudentStatuses, eventStart, and dateTbd
function getOptionLabelRenderFunction({
    targetStudentStatuses,
    eventStart,
}: {
    targetStudentStatuses: StudentStatus[];
    eventStart: Moment | null;
}) {
    return (cohort: CohortForAdminListsAttrs) => {
        const tags = buildTags({ cohort, targetStudentStatuses, eventStart });
        return <CohortSelectOption cohort={cohort} tags={tags} />;
    };
}

// This function looks at the dates on a cohort and the date of the event and figures out what the
// status of the cohort's students will be at the time of the event. For example, if the event happens
// between the start date and graduation date of the cohort, then the cohort's students will be "current"
function getTargetStatus(cohort: CohortForAdminListsAttrs, eventStartDate: Moment | null): StudentStatus | null {
    // If we don't know when the event starts, then we can't determine the status for particular cohorts
    if (!eventStartDate) return null;

    const eventStartTimestamp = eventStartDate.valueOf() / 1000;
    const oneDay = 60 * 60 * 24;

    if (
        eventStartTimestamp > cohort.startOfAdmissionCycle &&
        eventStartTimestamp < cohort.studentNetworkActivationDate
    ) {
        return 'prospect';
    }

    if (eventStartTimestamp > cohort.studentNetworkActivationDate && eventStartTimestamp < cohort.startDate) {
        return 'included_and_activated';
    }

    // We add some leeway here because students actually become current a bit before the cohort's start date and
    // they could remain current for a while after the graduation date
    if (
        eventStartTimestamp > cohort.startDate - 7 * oneDay &&
        eventStartTimestamp < cohort.graduationDate + 7 * oneDay
    ) {
        return 'current';
    }

    if (eventStartTimestamp > cohort.graduationDate) {
        return 'graduated';
    }

    return null;
}

// This function builds the tags that will be passed to CohortSelectOption, where there will be displayed
// as chips next to the formatted name of the cohort
function buildTags({
    cohort,
    eventStart: eventStartDate,
}: {
    cohort: CohortForAdminListsAttrs;
    targetStudentStatuses: StudentStatus[];
    eventStart: Moment | null;
}): TaggedSelectOptionTag[] {
    const defaultColor = 'default' as const;
    let targetStatus;

    if (eventStartDate) {
        targetStatus = getTargetStatus(cohort, eventStartDate);
    }

    // If the event doesn't have a start date, or if nothing has been selected in
    // targetStudentStatuses, then we can't determine the status on particular cohorts.
    if (targetStatus) {
        const label = {
            prospect: 'Prospect',
            included_and_activated: 'Registered',
            current: 'Current',
            graduated: 'Alumni',
        }[targetStatus];
        return [
            {
                label,
                color: defaultColor,
            },
        ];
    }
    return [];
}

// This function is used to hide cohorts whose students would not be able to see the event given
// the event's time and target statuses. For example, if an event happens after the start of a cohort,
// and the event is targeted to prospective students, then the event is irrelevant to the students in
// that cohort.
function cohortDoesNotMatchTargetStatuses({
    targetStudentStatuses,
    cohort,
    eventStart,
}: {
    targetStudentStatuses: StudentStatus[];
    cohort: CohortForAdminListsAttrs;
    eventStart: Moment | null;
}) {
    // If no time has been selected for the event, do not filter out any cohorts
    if (!eventStart) return true;

    const targetStatus = getTargetStatus(cohort, eventStart);

    // If the cohort is so far in the future that we will not have started accepting
    // appications yet at the time of the event, then there will be no target status and
    // we can hide it.
    if (!targetStatus) return false;

    // If no target statuses have been selected for the event, then do not hide any cohorts
    // that have a target status
    if (targetStudentStatuses.length === 0) return true;

    // Filter out cohorts who will not be in one of the targeted statuses at the selected
    // time for the event
    return targetStatus && targetStudentStatuses.includes(targetStatus);
}

function sortCohorts({
    a,
    b,
    eventStart,
}: {
    a: CohortForAdminListsAttrs;
    b: CohortForAdminListsAttrs;
    eventStart: Moment | null;
}) {
    // sort graduate cohorts last
    const [aStatus, bStatus] = [getTargetStatus(a, eventStart), getTargetStatus(b, eventStart)];
    const [aIsGraduated, bIsGraduated] = [aStatus === 'graduated', bStatus === 'graduated'];
    if (aIsGraduated !== bIsGraduated) {
        return aIsGraduated ? 1 : -1;
    }

    // Sort by program type first
    if (a.programType !== b.programType) {
        return a.programType > b.programType ? 1 : -1;
    }

    // Since the list of graduated cohorts will continue to grow and grow, sort the
    // most recent ones at the beginning of the list.
    if (aStatus === 'graduated' && bStatus === 'graduated') {
        return a.graduationDate > b.graduationDate ? -1 : 1;
    }

    // For all other (non-graduated) cohorts, sort them in chronological order
    return a.startDate > b.startDate ? 1 : -1;
}

/*
    This function is necessary because of the way we are dynamically building a set of checkboxes that
    are all bound to accessRules.targetProgramTypes in the form. Because of the way react-hook-form works,
    accessRules ends up being a mutable object that gets mutated whenever one of those checkboxes is checked.
    That makes it hard to memoize things and make sure we're only running code when necessary. This function
    does the work of creating an immutable copy of the values we care about in accessRules.targetProgramTypes.
    This function will return a new object whenever something actually changes in there.
*/

function useImmutableCheckedProgramTypes({ institutionEntry }: { institutionEntry: InstitutionEntry }) {
    const { watch } = useFormContext<EditStudentNetworkFormValues>();
    const [checkedProgramTypes, setCheckedProgramTypes] = useState<AnyObject<boolean>>({});
    const { accessRules } = watch();

    institutionEntry.programTypes.forEach(programType => {
        const checked = accessRules.targetProgramTypes[programType] || false;
        if (checkedProgramTypes[programType] === checked) return;

        setCheckedProgramTypes({
            ...checkedProgramTypes,
            [programType]: checked,
        });
    });

    return checkedProgramTypes;
}

/*
    This is a pure function. It will only return a different result if the arguments change,
    so it can be memoized and only called when necessary.
*/
function generateOptions({
    allCohortsForInstitution,
    institutionIsChecked,
    checkedProgramTypes,
    targetStudentStatuses,
    eventStart,
}: {
    allCohortsForInstitution: CohortForAdminListsAttrs[];
    institutionIsChecked: boolean;
    checkedProgramTypes: AnyObject<boolean>;
    targetStudentStatuses: StudentStatus[];
    eventStart: Moment | null;
}) {
    if (institutionIsChecked) {
        return [];
    }

    return allCohortsForInstitution
        .filter(cohort => !checkedProgramTypes[cohort.programType])
        .filter(cohort => cohortDoesNotMatchTargetStatuses({ targetStudentStatuses, cohort, eventStart }))
        .sort((a, b) => sortCohorts({ a, b, eventStart }));
}

/*
    This is a hook. It is responsible for watching state and the calling generateOptions only
    if something has changed that would affect the result of generateOptions.
*/
function useOptions({
    allCohortsForInstitution,
    institutionEntry,
    name,
}: {
    allCohortsForInstitution: CohortForAdminListsAttrs[];
    institutionEntry: InstitutionEntry;
    name: Path<EditStudentNetworkFormValues>;
}) {
    const { watch, setFieldValue } = useFormContext<EditStudentNetworkFormValues>();
    const value = watch(name) as string[] | undefined;
    const { accessRules, target_student_statuses: targetStudentStatuses } = watch();
    const eventStart = useEventStart();
    const institutionIsChecked = !!accessRules.targetInstitutionIds[institutionEntry.institutionId];
    const checkedProgramTypes = useImmutableCheckedProgramTypes({ institutionEntry });

    const options = useMemo(
        () =>
            generateOptions({
                allCohortsForInstitution,
                institutionIsChecked,
                checkedProgramTypes,
                targetStudentStatuses,
                eventStart,
            }),
        [allCohortsForInstitution, institutionIsChecked, checkedProgramTypes, targetStudentStatuses, eventStart],
    );

    // After the initial render, this will trigger when the options change. If an option
    // that was previously selected is no longer available, we remove it from the value.
    // Our Select component does not behave well if selected options get removed from the list (it unselects
    // everything in that case), but it handles it ok if options that are not selected get removed.
    useEffect(() => {
        if (!value) return;
        const selectableIds = options.map(opt => opt.id);
        const newValue: string[] = [];
        let valueChanged = false;
        value.forEach(id => {
            if (selectableIds.includes(id)) {
                newValue.push(id);
            } else {
                valueChanged = true;
            }
        });
        if (valueChanged) {
            setFieldValue(name, newValue);
        }
    }, [options, name, setFieldValue, value]);

    return options;
}

// See comment near CheckboxThatUpdatesAccessRule. This component is doing the same thing, dealing
// with the mutability of the accessRules property in the form.
function useTriggerAccessRules(name: Path<EditStudentNetworkFormValues>) {
    const { trigger, watch } = useFormContext<EditStudentNetworkFormValues>();
    const value = watch(name) as string[] | undefined;

    // Whenever the value here changes, we need to re-run the validation on accessRules
    useEffect(() => {
        trigger('accessRules');
    }, [value, trigger]);
}

// This component renders the selected cohort as chips underneath the select element
function SelectedCohortChips({
    institutionId,
    allCohortsForInstitution,
}: {
    institutionId: string;
    allCohortsForInstitution: CohortForAdminListsAttrs[];
}) {
    const { watch } = useFormContext<EditStudentNetworkFormValues>();
    const {
        accessRules: {
            targetCohortIdsByInstitution: { [institutionId]: selectedCohortIds },
        },
    } = watch();
    const selectedCohorts = useMemo(
        () => allCohortsForInstitution.filter(cohort => selectedCohortIds?.includes(cohort.id)),
        [allCohortsForInstitution, selectedCohortIds],
    );

    return (
        <div className="SelectedCohortChips">
            {selectedCohorts.map(cohort => (
                <Chip key={cohort.id} label={cohort.name} variant="outlined" color="primary" />
            ))}
        </div>
    );
}

function TargetCohortSelect({ allCohortsForInstitution, institutionEntry, name }: Props) {
    const { watch } = useFormContext<EditStudentNetworkFormValues>();
    const { target_student_statuses: targetStudentStatuses } = watch();
    const eventStart = useEventStart();
    const selectableCohorts = useOptions({ allCohortsForInstitution, institutionEntry, name });
    useTriggerAccessRules(name);

    const optionLabel = useMemo(
        () => getOptionLabelRenderFunction({ targetStudentStatuses, eventStart }),
        [targetStudentStatuses, eventStart],
    );

    return allCohortsForInstitution ? (
        <div className="TargetCohortSelect">
            <Select
                label="Cohorts"
                name={name}
                multiple
                fullWidth
                options={selectableCohorts}
                optionLabel={optionLabel}
                optionValue={opt => opt.id}
                renderValue={currentValue => renderValue(currentValue as string[])}
                disabled={selectableCohorts.length === 0}
            />
            <SelectedCohortChips
                institutionId={institutionEntry.institutionId}
                allCohortsForInstitution={allCohortsForInstitution}
            />
        </div>
    ) : (
        <p>Loading cohorts...</p>
    );
}

const Component = memo(TargetCohortSelect);
export default Component;

import { Select, DatePicker, MultiLocationInput } from 'FrontRoyalMaterialUiForm';
import moment from 'moment-timezone';

export default function getFilterFieldDefaultValue(filterFieldDefinition) {
    if ('default' in filterFieldDefinition) {
        return filterFieldDefinition.default;
    }

    switch (filterFieldDefinition.component) {
        case Select:
            return filterFieldDefinition.multiple ? [] : '';

        case MultiLocationInput:
            return [];

        case DatePicker:
            return moment();

        default:
            return '';
    }
}

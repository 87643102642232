import { useState } from 'react';
import { Alert, Badge, Box, Button } from '@mui/material';
import { ArrowBack, CloudDownload, CloudUpload } from '@mui/icons-material';
import { useCohorts } from 'UserAdministrationTab/redux/hooks';
import ExportHandler from './ExportHandler/ExportHandler';
import ImportHandler from './ImportHandler/ImportHandler';

const AdminCohortEventsPorter = () => {
    const { availableCohorts: cohorts } = useCohorts();

    const [activeSection, setActiveSection] = useState<'main' | 'export' | 'import'>('main');
    const [sectionsVisible, setSectionsVisible] = useState<boolean>(true);

    const [importHandlerOpen, setImportHandlerOpen] = useState<boolean>(false);

    const handleSectionChange = (section: 'main' | 'export' | 'import') => {
        setSectionsVisible(false);
        setTimeout(() => {
            setActiveSection(section);
            setSectionsVisible(true);
        }, 300);
    };

    const backButton = (
        <Button startIcon={<ArrowBack />} onClick={() => handleSectionChange('main')} variant="outlined">
            Back
        </Button>
    );

    const renderSectionContent = () => {
        switch (activeSection) {
            case 'export':
                if (!cohorts || cohorts.length === 0) {
                    return (
                        <>
                            <Box sx={{ flexGrow: 1, mr: 2 }}>
                                <Alert severity="warning">No cohorts available for export</Alert>
                            </Box>
                            {backButton}
                        </>
                    );
                }
                return (
                    <>
                        <Box sx={{ flexGrow: 1, mr: 2 }}>
                            <ExportHandler cohorts={cohorts || []} />
                        </Box>
                        <Button
                            startIcon={<ArrowBack />}
                            onClick={() => handleSectionChange('main')}
                            variant="outlined"
                        >
                            Back
                        </Button>
                    </>
                );
            default:
                return (
                    <>
                        <Button
                            startIcon={
                                // eslint-disable-next-line react/jsx-wrap-multilines
                                <Badge>
                                    <CloudDownload />
                                </Badge>
                            }
                            onClick={() => handleSectionChange('export')}
                            variant="outlined"
                            sx={{ mr: 2 }}
                        >
                            Export
                        </Button>
                        <Button
                            startIcon={
                                // eslint-disable-next-line react/jsx-wrap-multilines
                                <Badge color="secondary">
                                    <CloudUpload />
                                </Badge>
                            }
                            onClick={() => setImportHandlerOpen(true)}
                            variant="outlined"
                        >
                            Import
                        </Button>
                        <ImportHandler
                            open={importHandlerOpen}
                            onClose={() => setImportHandlerOpen(false)}
                            cohorts={cohorts || []}
                        />
                    </>
                );
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                height: '48px',
                maxWidth: 500,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    opacity: sectionsVisible ? 1 : 0,
                    transition: 'opacity 0.3s ease-in-out',
                    justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                }}
            >
                {renderSectionContent()}
            </Box>
        </Box>
    );
};

export default AdminCohortEventsPorter;

/**
 * This component handles the review and confirmation step of the event import process.
 * We show the user a small preview table of the imported records and allow for a couple
 * of editing opportunities before the final import (dates and published status). The
 * table is expandable to show all columns if needed. The import process will by default
 * use the existing targeting data from the imported records, but we allow the user
 * to override this targeting with a cohort selection and student status selection.
 */

import { Fragment, useState, useEffect, useMemo, useRef, useLayoutEffect } from 'react';
import {
    Alert,
    Box,
    Typography,
    RadioGroup,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    Chip,
    Divider,
    Collapse,
    Card,
    CardHeader,
    CardContent,
    FormControlLabel,
    Radio,
} from '@mui/material';
import { ArticleOutlined, CrisisAlert } from '@mui/icons-material';
import type moment from 'moment';
import { type CohortForAdminListsAttrs } from 'Cohorts';
import {
    type PortedStudentNetworkEvent,
    type ImportData,
    type ImportButtonRef,
} from '../AdminCohortEventsPorter.types';
import HierarchicalCohortAutocomplete from '../HierarchicalCohortAutocomplete';
import {
    handleTitleChange,
    handleDateChange,
    handlePublishedChange,
    prepareImportData,
    getTargetingOptions,
} from './ReviewRecordsHelpers';
import ReviewTable from './ReviewTable';

interface ReviewRecordsProps {
    title: string;
    recordsToImport: PortedStudentNetworkEvent[];
    cohorts: CohortForAdminListsAttrs[];
    onConfirmImport: (reviewedImportData: ImportData) => void;
    importButtonRef: React.RefObject<ImportButtonRef>;
}

interface StatusOption {
    key: string;
    label: string;
}

const ReviewRecords: React.FC<ReviewRecordsProps> = ({
    title,
    recordsToImport,
    cohorts,
    onConfirmImport,
    importButtonRef,
}) => {
    const [editedRecords, setEditedRecords] = useState<PortedStudentNetworkEvent[]>(() => {
        if (Array.isArray(recordsToImport) && recordsToImport.length > 0) {
            return JSON.parse(JSON.stringify(recordsToImport));
        }
        return [];
    });
    const [hasChanges, setHasChanges] = useState(false);
    const [targetStudentStatuses, setTargetStudentStatuses] = useState<StatusOption[]>([]);
    const targetStudentStatusOptions = useMemo<StatusOption[]>(
        () => [
            { key: 'prospect', label: 'Prospective Students' },
            { key: 'included_and_activated', label: 'Registered Students (before cohort start)' },
            { key: 'current', label: 'Current Students' },
            { key: 'graduated', label: 'Alumni' },
        ],
        [],
    );

    const [importOption, setImportOption] = useState<'as-is' | 'override'>('as-is');
    const [selectedCohorts, setSelectedCohorts] = useState<CohortForAdminListsAttrs[]>([]);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const expandableParentRef = useRef<HTMLDivElement>(null);
    const [error, setError] = useState<string | null>(null);

    const scrollToBottom = () => {
        if (expandableParentRef.current) {
            expandableParentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    useLayoutEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        if (importOption === 'override') {
            timeout = setTimeout(() => scrollToBottom(), 150);
        }
        return () => clearTimeout(timeout);
    }, [importOption]);

    useEffect(() => {
        if (recordsToImport?.length > 0) {
            const firstRecord = recordsToImport[0];
            if (firstRecord.target_cohort_ids) {
                setSelectedCohorts(cohorts.filter(c => firstRecord.target_cohort_ids.includes(c.id)));
            }
            if (firstRecord.target_student_statuses) {
                setTargetStudentStatuses(
                    targetStudentStatusOptions.filter(option =>
                        (firstRecord.target_student_statuses as string[]).includes(option.key),
                    ),
                );
            }
        }
    }, [recordsToImport, cohorts, targetStudentStatusOptions]);

    useEffect(() => {
        const changedRecordsCount = editedRecords.filter(
            (record, index) => JSON.stringify(record) !== JSON.stringify(recordsToImport[index]),
        ).length;
        setHasChanges(changedRecordsCount > 0);
    }, [editedRecords, recordsToImport]);

    useEffect(() => {
        const canImport = editedRecords.length > 0 && (importOption === 'as-is' || selectedCohorts.length > 0);
        importButtonRef.current?.setDisabled(!canImport);
        importButtonRef.current?.setOnClick(() => setConfirmationDialogOpen(true));
    }, [editedRecords, importOption, selectedCohorts, importButtonRef]);

    const handleTitleChangeWrapper = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditedRecords(handleTitleChange(editedRecords, index)(event));
    };

    const handleDateChangeWrapper =
        (index: number, field: 'start_time' | 'end_time') => (newValue: moment.Moment | null) => {
            const result = handleDateChange(editedRecords, index, field)(newValue);
            if (result.error) {
                setError(result.error);
            } else {
                setEditedRecords(result.records);
                setError(null);
            }
        };

    const handlePublishedChangeWrapper = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditedRecords(handlePublishedChange(editedRecords, index)(event));
    };

    const handleConfirmImport = () => {
        if (selectedCohorts.length === 0 && importOption === 'override') {
            setError('Please select at least one cohort to override targeting');
            return;
        }

        setError(null);
        setConfirmationDialogOpen(false);
        onConfirmImport(prepareImportData(editedRecords, importOption, selectedCohorts, targetStudentStatuses));
    };

    const handleCohortChange = (targetCohorts: CohortForAdminListsAttrs | CohortForAdminListsAttrs[] | [] | null) => {
        setSelectedCohorts(Array.isArray(targetCohorts) ? targetCohorts : targetCohorts ? [targetCohorts] : []);
    };

    const resetChanges = () => {
        setEditedRecords(JSON.parse(JSON.stringify(recordsToImport)));
        setHasChanges(false);
    };

    const renderTargetingOption = (value: string, label: string, icon: React.ReactNode, explanation: string) => (
        <FormControlLabel
            value={value}
            control={<Radio />}
            label={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>{icon}</Box>
                    <Box>
                        <Typography variant="body1">{label}</Typography>
                        <Typography variant="body2" color="text.secondary">
                            {explanation}
                        </Typography>
                    </Box>
                </Box>
            }
            sx={{
                width: '100%',
                m: 0,
                p: 3,
                '&:hover': {
                    backgroundColor: 'action.hover',
                },
                transition: 'background-color 0.3s',
            }}
        />
    );

    return (
        <Box sx={{ my: 2 }}>
            <Typography variant="h6" gutterBottom>
                {title || 'Review Import'}
            </Typography>

            <ReviewTable
                editedRecords={editedRecords}
                handleTitleChange={handleTitleChangeWrapper}
                handleDateChange={handleDateChangeWrapper}
                handlePublishedChange={handlePublishedChangeWrapper}
                hasChanges={hasChanges}
                resetChanges={resetChanges}
            />

            {error && (
                <Alert severity="error" sx={{ mt: 2, mb: 3 }}>
                    {error}
                </Alert>
            )}

            <Card
                elevation={0}
                sx={{
                    mt: 4,
                    borderRadius: 2,
                    overflow: 'hidden',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    backgroundColor: 'grey.50',
                }}
                ref={expandableParentRef}
            >
                <CardHeader
                    sx={{ p: 2, backgroundColor: 'grey.100' }}
                    title="Targeting Options"
                    titleTypographyProps={{ variant: 'h6' }}
                />
                <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                    <RadioGroup
                        value={importOption}
                        onChange={e => setImportOption(e.target.value as 'as-is' | 'override')}
                    >
                        {getTargetingOptions.map((option, index) => (
                            <Fragment key={option.value}>
                                {renderTargetingOption(
                                    option.value,
                                    option.label,
                                    option.icon === 'ArticleOutlined' ? <ArticleOutlined /> : <CrisisAlert />,
                                    option.explanation,
                                )}
                                {index < getTargetingOptions.length - 1 && <Divider />}
                            </Fragment>
                        ))}
                    </RadioGroup>
                </CardContent>
                <Collapse in={importOption === 'override'} timeout={150} unmountOnExit>
                    <CardContent sx={{ p: 4 }}>
                        <Typography variant="subtitle1" gutterBottom>
                            Select Target Cohorts
                        </Typography>
                        <HierarchicalCohortAutocomplete
                            cohorts={cohorts}
                            onChange={handleCohortChange}
                            multiple
                            value={selectedCohorts}
                        />

                        <Typography variant="subtitle1" sx={{ mt: 3, mb: 1 }}>
                            Target Student Statuses
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {targetStudentStatusOptions.map(option => (
                                <Chip
                                    key={option.key}
                                    label={option.label}
                                    onClick={() => {
                                        const newStatuses = targetStudentStatuses.some(s => s.key === option.key)
                                            ? targetStudentStatuses.filter(s => s.key !== option.key)
                                            : [...targetStudentStatuses, option];
                                        setTargetStudentStatuses(newStatuses);
                                    }}
                                    color={
                                        targetStudentStatuses.some(s => s.key === option.key) ? 'primary' : 'default'
                                    }
                                    variant={
                                        targetStudentStatuses.some(s => s.key === option.key) ? 'filled' : 'outlined'
                                    }
                                />
                            ))}
                        </Box>
                    </CardContent>
                </Collapse>
            </Card>

            <Dialog open={confirmationDialogOpen} onClose={() => setConfirmationDialogOpen(false)}>
                <DialogTitle>
                    Import {editedRecords.length} event{editedRecords.length === 1 ? '' : 's'}?
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        This will create {editedRecords.length} new Student Network Event
                        {editedRecords.length === 1 ? '' : 's'}.
                    </Typography>
                    {(hasChanges || importOption === 'override') && (
                        <Alert severity="info" sx={{ mt: 2 }}>
                            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                                {hasChanges && <span>You have made changes to the source records.</span>}
                                {importOption === 'override' && (
                                    <span>You have chosen to override existing targeting.</span>
                                )}
                                <strong>These changes will be imported.</strong>
                            </Grid>
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmationDialogOpen(false)}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleConfirmImport}>
                        Import Events
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
export default ReviewRecords;

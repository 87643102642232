import { useEffect, useState, useMemo, useCallback } from 'react';
import { Alert, Box, Typography, CircularProgress, Button, Fab } from '@mui/material';
import { green } from '@mui/material/colors';
import { Check } from '@mui/icons-material';
import { type AdminStudentNetworkEvent } from 'StudentNetworkEvent';
import { type ImportData } from '../AdminCohortEventsPorter.types';

interface CreateEventsProps {
    title: string;
    importRequestData: ImportData | null;
    onImportComplete: (events: AdminStudentNetworkEvent[]) => void;
    onImportFailed: () => void;
    onBack: () => void;
}

export const CreateEvents: React.FC<CreateEventsProps> = ({
    title,
    importRequestData,
    onImportComplete,
    onImportFailed,
    onBack,
}) => {
    const [status, setStatus] = useState<'loading' | 'slow' | 'success' | 'error'>('loading');
    const [loadingMessage, setLoadingMessage] = useState<string>('This may take a few moments');
    const [importedEvents, setImportedEvents] = useState<AdminStudentNetworkEvent[]>([]);
    const [startTime] = useState<number>(Date.now());

    const importParams = useMemo(
        () => ({
            records: importRequestData?.recordsToImport,
            target_cohort_ids: importRequestData?.selectedCohorts.map(cohort => cohort.id),
            target_student_statuses: importRequestData?.targetStudentStatuses,
            override_targeting: importRequestData?.importOption === 'override',
        }),
        [importRequestData],
    );

    const successFabSx = {
        bgcolor: green[500],
        scale: '0%',
        transition: 'background-color 0.3s ease, scale .5s ease',
        boxShadow: 'none',
        '&:hover': {
            bgcolor: green[500],
        },
        ...(status === 'success' && {
            scale: '100%',
        }),
    };

    useEffect(() => {
        const checkLoadingTime = () => {
            const elapsedTime = Date.now() - startTime;

            if (elapsedTime > 15000 && status !== 'error') {
                setStatus('error');
                setLoadingMessage('The import has likely failed without returning an error.');
            } else if (elapsedTime > 5000 && status === 'loading') {
                setStatus('slow');
                setLoadingMessage('This is taking longer than expected');
            }
        };

        const timer = setInterval(checkLoadingTime, 1000);

        return () => clearInterval(timer);
    }, [startTime, status]);

    const importEvents = useCallback(async () => {
        try {
            const formData = new FormData();
            Object.entries(importParams).forEach(([key, value]) => {
                if (value) {
                    formData.append(key, JSON.stringify(value));
                }
            });
            const response = await fetch('/api/student_network_events/import', {
                method: 'POST',
                body: formData,
            });

            const result = await response.json();

            if (!response.ok) {
                throw new Error(result.error || 'An error occurred while importing events');
            }

            const newEvents = result.contents.student_network_events;
            setImportedEvents(newEvents);
            setStatus('success');
            setLoadingMessage(`Successfully imported ${newEvents.length} events`);
        } catch (error) {
            onImportFailed();
            setStatus('error');
            setLoadingMessage(error instanceof Error ? error.message : 'An error occurred while importing events');
        }
    }, [importParams, onImportFailed]);

    useEffect(() => {
        if (importRequestData) {
            importEvents();
        }
    }, [importRequestData, importEvents]);

    useEffect(() => {
        if (status === 'success') {
            const timeout = setTimeout(() => {
                onImportComplete(importedEvents);
            }, 2000);
            return () => clearTimeout(timeout);
        }
        return undefined;
    }, [status, importedEvents, onImportComplete]);

    return (
        <Box sx={{ my: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {importRequestData ? (
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="h6">
                        {status === 'error' ? 'Import failed' : status === 'success' ? 'Import complete' : title}
                    </Typography>
                    <Box sx={{ mt: 1, position: 'relative' }}>
                        <CircularProgress
                            size={50}
                            sx={{
                                color:
                                    status === 'success'
                                        ? green[500]
                                        : status === 'error'
                                        ? 'error.main'
                                        : status === 'slow'
                                        ? 'warning.main'
                                        : 'primary.main',
                                position: 'absolute',
                                inset: 0,
                                margin: 'auto',
                            }}
                            variant={['error', 'success'].includes(status) ? 'determinate' : 'indeterminate'}
                            value={['error', 'success'].includes(status) ? 100 : undefined}
                        />
                        <Fab sx={successFabSx} onClick={() => onImportComplete([])}>
                            <Check />
                        </Fab>
                    </Box>
                    {['loading', 'slow', 'success'].includes(status) && (
                        <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                            {loadingMessage}
                        </Typography>
                    )}
                    {status === 'error' && (
                        <Alert
                            severity="error"
                            sx={{ width: '100%', maxWidth: 400, mt: 2, textAlign: 'left' }}
                            action={
                                // eslint-disable-next-line react/jsx-wrap-multilines
                                <Button color="inherit" size="small" onClick={onBack} sx={{ alignSelf: 'center' }}>
                                    Back
                                </Button>
                            }
                        >
                            {loadingMessage}
                        </Alert>
                    )}
                </Box>
            ) : (
                <Typography variant="h6" gutterBottom>
                    No data to import
                </Typography>
            )}
        </Box>
    );
};
export default CreateEvents;

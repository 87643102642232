import { useEffect } from 'react';
import { Checkbox } from 'FrontRoyalMaterialUiForm';
import { useFormContext, type Path } from 'FrontRoyalReactHookForm';
import { type EditStudentNetworkFormValues } from 'AdminEditStudentNetworkEvent/AdminEditStudentNetworkEvent.types';

/*
    Because of the way we set dynamically build a set of checkboxes and bind them to values inside of
    accessRules, react-hook-form sets up accessRules as a mutable object. For that reason, it won't
    re-validate automatically whenever we check one of the checkboxes. This component makes sure that
    when we check a checkbox that is bound to a value withing AccessRules, then accessRules will re-validate.
*/
export default function CheckboxThatUpdatesAccessRules(props: {
    name: Path<EditStudentNetworkFormValues>;
    label: string;
    disabled?: boolean;
}) {
    const { name } = props;
    const { watch, trigger } = useFormContext<EditStudentNetworkFormValues>();
    const value = watch(name);

    useEffect(() => {
        trigger('accessRules');
    }, [value, trigger]);

    return <Checkbox {...props} />;
}

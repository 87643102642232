import { type StudentNetworkInstance } from 'StudentNetwork/StudentNetwork.types';
import { type CohortForAdminListsAttrs } from 'Cohorts';
import { type AdminStudentNetworkEvent } from 'StudentNetworkEvent';
import { type PortedStudentNetworkEvent } from './AdminCohortEventsPorter.types';

export const fetchEventsForCohort = async ({
    StudentNetworkEvent,
    selectedCohortId,
}: {
    StudentNetworkEvent: StudentNetworkInstance;
    selectedCohortId: string;
}): Promise<AdminStudentNetworkEvent[]> => {
    const queryFilters = {
        cohort: selectedCohortId,
        editor: true,
        start_time: 0,
        end_time: new Date('2099/01/01').getTime() / 1000,
        include_tbd: true,
    };
    const response = await StudentNetworkEvent.index({
        fields: ['ADMIN_FIELDS'],
        filters: queryFilters,
        limit: 9999,
        offset: 0,
        sort: 'start_time',
        direction: 'asc',
    });

    return response.result as AdminStudentNetworkEvent[];
};

/**
 * Before exporting, we need to remove any events that target multiple cohorts IF those cohorts
 * aren't all in the same cycle. This function keeps it simple
 * If our target cohort's name ends in numbers, we assume it's a cycle number and we
 * should only include events that target cohorts with names ending in the same number.
 */
export const filterMultiCycleEvents = (
    eventsForExport: PortedStudentNetworkEvent[],
    selectedCohortId: string,
    selectedCohortName: string,
    cohorts: CohortForAdminListsAttrs[],
): PortedStudentNetworkEvent[] => {
    // We only need to filter events if the selected cohort's name ends in numbers.
    const targetCohortCycle = selectedCohortName.match(/\d+$/);
    if (targetCohortCycle) {
        eventsForExport = eventsForExport.filter(event => {
            event.extra_export_data = {
                selected_cohort: selectedCohortName,
                other_targeted_cohorts: '',
            };

            // If the event only targets one cohort (should be the selected cohort), we don't need to filter it.
            if (event.target_cohort_ids?.length <= 1) {
                return true;
            }

            const otherCohortsTargetedByEvent =
                (event.target_cohort_ids as string[])
                    .filter(id => id !== selectedCohortId)
                    .map(id => cohorts.find(c => c.id === id)) || [];

            event.extra_export_data.other_targeted_cohorts = otherCohortsTargetedByEvent.map(c => c!.name).join(', ');

            return otherCohortsTargetedByEvent.every(otherCohort => {
                const otherCohortCycle = otherCohort?.name.match(/\d+$/);
                return otherCohortCycle && otherCohortCycle[0] === targetCohortCycle[0];
            });
        });
    }
    return eventsForExport;
};

import moment from 'moment';
import { tableToCSV } from 'AdminTable/CSVExport';
import { type Column, type Row } from 'react-table';
import { type CohortForAdminListsAttrs } from 'Cohorts';
import { type AdminStudentNetworkEvent } from 'StudentNetworkEvent';
import { buildMessageLines } from 'FrontRoyalMaterialUi/SnackbarAlert';
import {
    type PortedStudentNetworkEvent,
    type StudentNetworkEventCSVColumns,
    type ExportMessage,
    StudentNetworkEventCSVHeaders,
} from '../AdminCohortEventsPorter.types';
import { filterMultiCycleEvents } from '../eventDataHelpers';

const unixToDate = (date: number) => (date ? String(moment.unix(date).format('MM/DD/YY')) : null);
const unixToDateTime = (date: number | null | undefined) =>
    date ? String(moment.unix(date).format('MM/DD/YY HH:mm')) : null;

export const generateExportTable = (events: PortedStudentNetworkEvent[], cohort: CohortForAdminListsAttrs) => {
    const columns: StudentNetworkEventCSVColumns = [
        { id: 'id' },
        { id: 'selectedCohort', accessor: row => row.extra_export_data?.selected_cohort },
        { id: 'otherTargetedCohorts', accessor: row => row.extra_export_data?.other_targeted_cohorts },
        { id: 'title' },
        { id: 'event_type' },
        { id: 'start_time', accessor: row => unixToDateTime(row.start_time) },
        { id: 'end_time', accessor: row => unixToDateTime(row.end_time) },
        { id: 'timezone' },
        { id: 'cohortStartDate', accessor: () => unixToDate(cohort.startDate) },
        {
            id: 'cohortStartToEventStart',
            accessor: row =>
                row.start_time
                    ? `${moment.unix(row.start_time).diff(moment.unix(cohort.startDate), 'days')} days`
                    : null,
        },
        { id: 'date_tbd' },
        { id: 'date_tbd_description' },
        { id: 'description' },
        { id: 'location' },
        { id: 'location_name' },
        { id: 'place_id' },
        { id: 'place_details', accessor: row => JSON.stringify(row.place_details) },
        { id: 'rsvp_status' },
        { id: 'external_rsvp_url' },
        { id: 'internal_notes' },
        { id: 'image_id' },
        { id: 'image_url', accessor: row => row.image?.formats?.original?.url },
        { id: 'target_cohort_ids' },
        { id: 'target_institution_ids' },
        { id: 'target_program_types' },
        { id: 'target_student_statuses' },
        { id: 'published' },
        { id: 'featured' },
    ];

    const rows = events.map(event => {
        const values = {} as Record<string, unknown>;
        columns.forEach(column => {
            column.Header = StudentNetworkEventCSVHeaders[column.id];
            // For most columns, the id is also the accessor, corresponding to a property on the event object,
            // but we also allow the accessor to be a function that takes the event object and returns a string.
            // This allows us to derive values for columns that aren't directly on the event object and mimics the
            // behavior of react-table's accessor function.
            if (column.id) {
                if (typeof column.accessor === 'function') {
                    values[column.id] = column.accessor(event);
                } else if (typeof column.accessor === 'string') {
                    values[column.id] = event[column.accessor as keyof PortedStudentNetworkEvent];
                } else {
                    values[column.id] = event[column.id as keyof PortedStudentNetworkEvent];
                }
            }
        });
        return { values };
    });

    return { columns, rows };
};

export const exportToCSV = (
    { cohortName, columns, rows }: { cohortName: string; columns: Column[]; rows: Row[] },
    setExporting: (exporting: boolean) => void,
    setExportMessage: (message: ExportMessage) => void,
) => {
    const { url, download } = tableToCSV({ filePrefix: `${cohortName}-events`, table: { columns, rows } });
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = download;
    anchor.click();
    setExportMessage({ severity: 'success', message: `Success! ${rows.length} events exported.` });
    setExporting(false); // Stop the loading spinner
    setTimeout(() => {
        window.URL.revokeObjectURL(url); // Release blob from memory
    }, 2500);
};

export const filterAndExportEvents = (
    events: AdminStudentNetworkEvent[],
    cohort: CohortForAdminListsAttrs,
    cohorts: CohortForAdminListsAttrs[],
    setExporting: (exporting: boolean) => void,
    setExportMessage: (message: ExportMessage) => void,
) => {
    const filteredEventData = filterMultiCycleEvents(
        events as PortedStudentNetworkEvent[],
        cohort.id,
        cohort.name,
        cohorts,
    );
    const { columns, rows } = generateExportTable(filteredEventData, cohort);
    if (rows.length === 0) {
        setExportMessage({
            severity: 'warning',
            message: buildMessageLines([
                `No exportable events found for ${cohort.name}.`,
                'Events targeting cohorts across multiple cycles are excluded from export.',
            ]),
        });
        setExporting(false);
        return;
    }
    exportToCSV(
        { cohortName: cohort.name, columns: columns as Column<object>[], rows: rows as Row<object>[] },
        setExporting,
        setExportMessage,
    );
};

/* eslint-disable no-nested-ternary */
import { useContext, useMemo } from 'react';
import { InputAdornment } from '@mui/material';
import { AttachMoney } from '@mui/icons-material';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { FieldRow } from 'FrontRoyalForm';
import { DatePicker, Select, TextField } from 'FrontRoyalMaterialUiForm';
import {
    providerOptionsForSelectInput,
    TRANSACTION_TYPE_OPTIONS,
    externalLink,
    PROVIDER_SVB,
    PROVIDER_PAYPAL,
} from 'BillingTransaction';
import BillingTransactionContext from './BillingTransactionContext';
import disabledIfUnselectableProvider from './disabledIfUnselectableProvider';
import tuitionContractSelectOptions from './tuitionContractSelectOptions';

function ProviderRow() {
    const { billingTransaction } = useContext(BillingTransactionContext);
    const { disabled } = disabledIfUnselectableProvider();

    return (
        <FieldRow>
            <Select
                name="provider"
                label="Provider"
                options={providerOptionsForSelectInput(billingTransaction)}
                optionLabel={option => option.name}
                disabled={disabled}
                fullWidth
                required
            />
        </FieldRow>
    );
}
const ProviderTransactionIdRow = () => {
    const { watch } = useFormContext();
    const { provider, provider_transaction_id } = watch();
    const { billingTransaction } = useContext(BillingTransactionContext);
    const _externalLink = externalLink(provider, provider_transaction_id, billingTransaction.stripe_livemode);
    const { disabled } = disabledIfUnselectableProvider();

    return (
        <FieldRow>
            <TextField
                label="Provider Transaction ID"
                name="provider_transaction_id"
                disabled={disabled}
                fullWidth
                required
            />

            {_externalLink && (
                <a href={_externalLink} target="_blank" rel="noopener noreferrer">
                    &nbsp;
                    <i className="fas fa-external-link-alt" />
                </a>
            )}

            <div className="sub-text note">
                {provider === PROVIDER_SVB ? (
                    <>
                        Use the <i>Bank Reference</i> number.
                    </>
                ) : provider === PROVIDER_PAYPAL ? (
                    <>
                        Use the <i>Transaction ID</i>.
                    </>
                ) : null}
            </div>
        </FieldRow>
    );
};

// this field is display-only, and only shown when there's a value present
function PaymentMethodCategoryRow() {
    return (
        <FieldRow>
            <TextField label="Payment Method" name="payment_method_category" disabled="true" fullWidth />
        </FieldRow>
    );
}

function TransactionTypeRow() {
    const { reset, watch } = useFormContext();
    const { disabled } = disabledIfUnselectableProvider();

    const onChange = e => {
        reset({ ...watch(), transaction_type: e.target.value, provider: '', provider_transaction_id: '' });
    };

    return (
        <FieldRow>
            <Select
                label="Type"
                name="transaction_type"
                options={TRANSACTION_TYPE_OPTIONS}
                disabled={disabled}
                onChange={onChange}
                fullWidth
                required
            />
        </FieldRow>
    );
}

function AmountRow() {
    const { trigger, setFieldValue } = useFormContext();
    const { disabled } = disabledIfUnselectableProvider();
    const InputProps = {
        startAdornment: (
            <InputAdornment position="start">
                <AttachMoney />
            </InputAdornment>
        ),
    };

    const onChange = e => {
        setFieldValue('amount', e.target.value);
        trigger(['amount', 'refunds']);
    };
    return (
        <FieldRow>
            <TextField
                label="Amount"
                name="amount"
                disabled={disabled}
                InputProps={InputProps}
                fullWidth
                required
                onChange={onChange}
            />
        </FieldRow>
    );
}

function TuitionContractRow() {
    const { billingTransaction } = useContext(BillingTransactionContext);
    // you cannot change the Tuition Contract once it has been attached:
    const disabled = useMemo(() => !!billingTransaction.tuition_contract_id, [billingTransaction]);

    return (
        <FieldRow>
            <Select
                label="Tuition Contract"
                name="tuition_contract_id"
                options={tuitionContractSelectOptions()}
                disabled={disabled}
                fullWidth
            />
        </FieldRow>
    );
}

function TransactionTimeRow() {
    const { disabled } = disabledIfUnselectableProvider();
    return (
        <FieldRow>
            <DatePicker label="Time" name="transaction_time" disabled={disabled} required />
        </FieldRow>
    );
}

function DescriptionRow() {
    const { disabled } = disabledIfUnselectableProvider();
    return (
        <FieldRow>
            <TextField multiline label="Description" name="description" disabled={disabled} fullWidth />
        </FieldRow>
    );
}

export {
    ProviderRow,
    ProviderTransactionIdRow,
    PaymentMethodCategoryRow,
    TransactionTypeRow,
    TransactionTimeRow,
    AmountRow,
    TuitionContractRow,
    DescriptionRow,
};

import { useState, useEffect, useRef, useReducer, useCallback } from 'react';
import { useLocation } from 'react-use';
import { find, clone, isEqual, without, some, identity } from 'lodash/fp';
import { generateGuid } from 'guid';

// Function that finds the record for the edit form
// when there is an id in the query params
function ensureRecordLoaded({
    recordId,
    records,
    setRecord,
    makeRequest,
    defaultFilters,
    setRecordId,
    loadingRecordForId,
    setLoadingRecordForId,
    getBlankRecord,
}) {
    // if the defaultFilters aren't setup yet,
    // we have to wait until they are
    if (!defaultFilters) {
        return null;
    }

    if (recordId === 'create') {
        return setRecord(getBlankRecord());
    }

    // First, check for the record in the list of records
    // we already have
    const record = find({
        id: recordId,
    })(records);

    if (record) {
        return setRecord(record);
    }

    // If we didn't find it, but are already loading it,
    // don't do anything
    if (loadingRecordForId === recordId) {
        return null;
    }

    // If we aren't loading it, check the server.  This
    // assumes that the api supports an id filter
    setLoadingRecordForId(recordId);

    makeRequest({
        id: recordId,
        filters: defaultFilters,
    }).then(response => {
        const result = response.result;
        const resultRecord = find({
            id: recordId,
        })(result);

        // If we found the record on the server, set it
        if (resultRecord) {
            setRecord(resultRecord);
        }

        // If we didn't find the record, then remove the id
        // from the url and go back to the main table
        else {
            setRecordId(null);
        }
    });

    return null;
}

export default function useRecordIdParam({
    idParam,
    fetchData,
    defaultFilters,
    filters,
    getBlankRecord,
    requestedRecordsLength,
    sort,
    direction,
    onRecordsReset,
    quickFilterValue,
    setQuickFilterValue,
    serverPaginationAndSorting,
}) {
    const location = useLocation();
    const url = new URL(location.href);
    const recordId = url.searchParams.get(idParam);
    const [record, setRecord] = useState();
    const [records, setRecords] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [requestDetailsUsedToLoadRecords, setRequestDetailsUsedToLoadRecords] = useState();
    const [loadingRecordForId, setLoadingRecordForId] = useState();
    const forceUpdate = useState()[1];

    // reducer that keeps track of which requests are
    // in flight
    const [activeRequests, dispatchRequestAction] = useReducer((_activeRequests, action) => {
        if (action.type === 'requestSent') {
            _activeRequests = _activeRequests.concat([action.request]);
        } else if (action.type === 'requestCompleted') {
            _activeRequests = without([action.request])(_activeRequests);
        }
        return _activeRequests;
    }, []);
    const requestInFlight = some(identity)(activeRequests);

    // Wrapper for fetchData that uses the activeRequests
    // reducer to keep track of which requests are
    // in flight
    const makeRequest = useRef(_requestDetails => {
        const id = generateGuid();
        dispatchRequestAction({
            request: id,
            type: 'requestSent',
        });
        return fetchData(_requestDetails).finally(() => {
            // Use a setTimeout here so that the records can be
            // set in response to the api request before we announce
            // that the request is completed.
            setTimeout(() => {
                dispatchRequestAction({
                    request: id,
                    type: 'requestCompleted',
                });
            }, 0);
        });
    }).current;

    // callback that can be used to set the record
    // id in the future (i.e. by clicking on a row
    // in the table)
    const setRecordId = useRef(id => {
        if (id) {
            url.searchParams.set(idParam, id);
        } else {
            url.searchParams.delete(idParam);
        }

        const href = `${location.pathname}?${url.searchParams.toString()}`;
        window.history.replaceState({}, '', href);
        forceUpdate(href);
    }).current;

    // Callback function that will unload all the
    // records.  This is used, for example, after
    // a record is updated.
    const resetRecords = useCallback(() => {
        setRequestDetailsUsedToLoadRecords(null);
        setQuickFilterValue('');
        setRecords([]);
        onRecordsReset();
        setTotalCount(null);
    }, [setRecords, setRequestDetailsUsedToLoadRecords, onRecordsReset, setQuickFilterValue]);

    // This is the magic.  Watch for a whole bunch of things to
    // change and then make sure that we have the appropriate
    // records loaded
    useEffect(() => {
        // If the record we're expecting is already loaded, do nothing
        if (recordId && record && (record.id === recordId || recordId === 'create')) {
            return;
        }

        // Ensure that if we have a recordId, the record is
        // available.  We will first look in the list of loaded records (if
        // records are loaded already).  If we don't find it there, we
        // will make an api call to load up that one record.
        if (recordId) {
            ensureRecordLoaded({
                recordId,
                record,
                records,
                setRecord,
                makeRequest,
                defaultFilters,
                setRecordId,
                loadingRecordForId,
                setLoadingRecordForId,
                getBlankRecord,
            });
        } else {
            setRecord(null);
        }

        // If records or filters are not ready yet, do nothing
        if (!records || !filters) {
            return;
        }

        const requestDetails = {
            filters,
            sort,
            direction,
        };

        // if react-table's quickFilterValueValue is a non-empty string, that means the user
        // is QuickFiltering the existing dataset and shouldn't query for more data. there's no way
        // that a user could have set quickFilterValue initially, so we know that an initial makeRequest
        // call will always happen.
        if (quickFilterValue) {
            return;
        }

        // If filters, sort, or direction changes, then we need to throw away
        // the records we have and load up new records.
        if (
            requestDetailsUsedToLoadRecords &&
            (!isEqual(requestDetailsUsedToLoadRecords.sort, requestDetails.sort) ||
                !isEqual(requestDetailsUsedToLoadRecords.filters, requestDetails.filters) ||
                !isEqual(requestDetailsUsedToLoadRecords.direction, requestDetails.direction))
        ) {
            resetRecords();
            return;
        }

        // If there are no more records in the db, do not make another
        // request (purposely using `!=` instead of `!==`to get null and undefined)
        if (totalCount != null && totalCount === records.length) {
            return;
        }

        // If we don't have enough records loaded, then load up some more.  We
        // make this call even if we're showing a particular record initially,
        // so that the records are preloaded before we go back to the table.
        if (!requestInFlight && requestedRecordsLength > records.length) {
            requestDetails.offset = records.length;
            requestDetails.limit = requestedRecordsLength - records.length;

            setRequestDetailsUsedToLoadRecords(clone(requestDetails));
            makeRequest(requestDetails).then(response => {
                setRecords(records.concat(response.result));
                // if endpoint doesn't support server pagination, total_count will not be supplied. in that case,
                // use the length of the result array to calculate the total_count instead.
                const count =
                    serverPaginationAndSorting && response.meta.total_count
                        ? response.meta.total_count
                        : response.result.length;
                setTotalCount(count);
            });
        }
    }, [
        record,
        recordId,
        defaultFilters,
        records,
        setRecords,
        makeRequest,
        setRecordId,
        filters,
        requestDetailsUsedToLoadRecords,
        loadingRecordForId,
        setLoadingRecordForId,
        getBlankRecord,
        requestedRecordsLength,
        sort,
        direction,
        resetRecords,
        requestInFlight,
        totalCount,
        quickFilterValue,
        serverPaginationAndSorting,
    ]);

    return {
        recordId,
        setRecordId,
        record,
        records,
        setRecords,
        resetRecords,
        requestInFlight,
        totalCount,
        setTotalCount,
    };
}

import {
    useState,
    useEffect,
    useMemo,
    useCallback,
    type ComponentClass,
    type ReactElement,
    type Dispatch,
    type SetStateAction,
} from 'react';
import { theme } from 'FrontRoyalMaterialUiForm';
import { ThemeProvider, Container } from '@mui/material';
import { AngularContext, useAngularContext } from 'AngularContext';
import { type Iguana, type ngToast } from '@Types';
import { useTable, usePagination, useSortBy, useGlobalFilter, type SortingRule, type Column } from 'react-table';
import AdminTableContext from './AdminTableContext';
import { type AdminTableContextState } from './AdminTableContext.types';
import useRecordIdParam from './useRecordIdParam';
import useFilters from './useFilters';
import Table from './Table';
import { type ToolbarAction, type FilterField } from './AdminTable.types';

function showToastSuccess($injector: angular.auto.IInjectorService, msg: string) {
    $injector.get<ngToast>('ngToast').create({
        content: msg,
        className: 'success',
    });
}

interface Props {
    idParam: string;
    columns: Column<Record<string, unknown>>[];
    fetchData: Iguana<unknown>['index'];
    filterFields: FilterField[];
    EditComponent: ComponentClass;
    getBlankRecord: Iguana<unknown>['new'];
    serverPaginationAndSorting?: boolean;
    showQuickFilter?: boolean;
    showCSVExport?: boolean;
    customToolbarActions?: ToolbarAction[];
    klassName: string;
}

export default function AdminTable({
    idParam,
    columns,
    fetchData,
    filterFields,
    EditComponent,
    getBlankRecord,
    serverPaginationAndSorting = false,
    showQuickFilter = true,
    showCSVExport = false,
    customToolbarActions = [],
    klassName,
}: Props): ReactElement {
    const $injector = useAngularContext();
    const { collection } = $injector.get<{ collection: string }>(klassName);
    // Setup the filters
    const { filters, setFilters, defaultFilters } = useFilters({ collection, filterFields });
    // Because we are mutating the data state,
    // ReactTable is going to reset back to initialState when
    // we swap out the data. So we need to keep track of the current
    // pageIndex, pageSize, quickFilterValue, and sortBy outside of reactTable.
    const [sortBy, setSortBy] = useState<SortingRule<Record<string, unknown>>[]>([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [quickFilterValue, setQuickFilterValue] = useState<string>('');

    // Extract sort values
    const sort = sortBy[0]?.id;
    const direction = sortBy[0]?.desc ? 'desc' : 'asc';
    // Calculate how many records we will need preloaded before next page. Takes into account that data is
    // retrieved in batches and calculates records based on how many batches are required to ensure the next
    // page will have records before it is loaded.
    const pagesPerBatch = 2;
    const nextPage = pageIndex + 1;
    const batchLength = pageSize * pagesPerBatch;
    const requestedBatchesLength = Math.trunc(nextPage / pagesPerBatch); // Calculate only whole number of batches
    const requestedRecordsLength = serverPaginationAndSorting
        ? requestedBatchesLength * batchLength + batchLength
        : 9999;

    // Handle loading records and deciding when to show a single record to edit and
    // when to show the whole table
    const {
        recordId,
        setRecordId,
        record,
        records,
        totalCount,
        setTotalCount: setTotalCountBeforeType,
        resetRecords,
        requestInFlight,
    } = useRecordIdParam({
        idParam,
        fetchData,
        defaultFilters,
        filters,
        getBlankRecord,
        requestedRecordsLength,
        sort,
        direction,
        onRecordsReset: () => {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            reactTable.gotoPage(0);
        },
        quickFilterValue,
        setQuickFilterValue,
        serverPaginationAndSorting,
    });

    const setTotalCount = setTotalCountBeforeType as Dispatch<SetStateAction<number | null>>;

    const reactTable = useTable(
        {
            columns,
            data: records,
            initialState: { pageSize, pageIndex, sortBy },
            manualSortBy: serverPaginationAndSorting,
        },
        useGlobalFilter, // used to add QuickFilter functionality
        useSortBy,
        usePagination,
    );

    useEffect(() => setSortBy(reactTable.state.sortBy), [reactTable.state.sortBy]);
    useEffect(() => setPageSize(reactTable.state.pageSize), [reactTable.state.pageSize]);
    // Even though pageIndex will lock at '0' when a quickFilterValue is present,
    // Pagination still works because it relies on reactTable.state.pageIndex instead.
    useEffect(() => {
        setPageIndex(quickFilterValue ? 0 : reactTable.state.pageIndex);
    }, [reactTable.state.pageIndex, quickFilterValue]);
    useEffect(() => reactTable.setGlobalFilter(quickFilterValue), [reactTable, quickFilterValue]);

    const onRecordUpdated = useCallback(
        (showToast = true) => {
            // Since we can't really know if the new record or updated record
            // should be included in the current list based on the current filters,
            // We unload the records and force a reload
            resetRecords();
            if (showToast) {
                showToastSuccess($injector, 'Saved');
            }
        },
        [$injector, resetRecords],
    );

    const onRecordDeleted = useCallback(
        (showToast = true) => {
            resetRecords();
            setRecordId(null);
            if (showToast) {
                showToastSuccess($injector, 'Deleted');
            }
        },
        [$injector, setRecordId, resetRecords],
    );

    function goBack() {
        setRecordId(null);
    }

    const memoizedAdminTableContextValues: AdminTableContextState = useMemo(
        () => ({
            columns,
            setFilters,
            filters,
            defaultFilters,
            filterFields,
            reactTable,
            setRecordId,
            collection,
            record,
            onRecordUpdated,
            onRecordDeleted,
            loading: requestInFlight && reactTable.page.length === 0,
            totalCount: totalCount || 0,
            setTotalCount,
            showQuickFilter,
            setQuickFilterValue,
            quickFilterValue,
            showCSVExport,
            customToolbarActions,
            klassName,
        }),
        [
            collection,
            columns,
            defaultFilters,
            filterFields,
            filters,
            klassName,
            onRecordDeleted,
            onRecordUpdated,
            quickFilterValue,
            reactTable,
            record,
            requestInFlight,
            setFilters,
            setRecordId,
            setTotalCount,
            showCSVExport,
            showQuickFilter,
            customToolbarActions,
            totalCount,
        ],
    );

    return (
        <ThemeProvider theme={theme}>
            <AdminTableContext.Provider value={memoizedAdminTableContextValues}>
                <AngularContext.Provider value={$injector}>
                    {!recordId && records && filters && <Table />}
                    {record && (
                        <Container maxWidth="lg">
                            <EditComponent
                                {...{
                                    record,
                                    goBack,
                                    onRecordUpdated: () => onRecordUpdated(false),
                                    onRecordDeleted: () => onRecordDeleted(false),
                                    getBlankRecord,
                                    setRecordId,
                                }}
                            />
                        </Container>
                    )}
                </AngularContext.Provider>
            </AdminTableContext.Provider>
        </ThemeProvider>
    );
}

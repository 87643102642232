/* eslint-disable react/jsx-props-no-spreading */
import { useMemo } from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from 'FrontRoyalMaterialUiForm';
import { BackButton, EditFormActionButtons, useEditForm } from 'EditableThingsList';
import { FormProvider } from 'FrontRoyalReactHookForm';
import {
    toForm,
    fromForm,
    isPayment,
    validationSchema,
    hasUnselectableProvider,
    allowsManualRefunds,
} from 'BillingTransaction';
import {
    ProviderRow,
    ProviderTransactionIdRow,
    PaymentMethodCategoryRow,
    TransactionTypeRow,
    TransactionTimeRow,
    AmountRow,
    TuitionContractRow,
    DescriptionRow,
} from './fields';
import BillingTransactionContext from './BillingTransactionContext';
import RefundsRow from './refunds';
import './AdminEditBillingTransaction.scss';

function AdminEditBillingTransaction(props) {
    const { thing: billingTransaction, editableThingsListViewModel, passthrough } = props;
    const owner = passthrough.owner;

    const memoizedContextValues = useMemo(
        () => ({
            billingTransaction,
            owner,
        }),
        [billingTransaction, owner],
    );

    const formResources = useEditForm({
        thing: billingTransaction,
        editableThingsListViewModel,
        fromForm,
        toForm,

        validationSchema,
        metaData: () => ({ owner_id: owner.id }),
    });

    if (!formResources) {
        return <>Loading...</>;
    }

    const [formFunctions, onSubmit] = formResources;
    const { watch, handleSubmit } = formFunctions;
    const values = watch();

    return (
        <ThemeProvider theme={theme}>
            <BackButton label=" Back to transactions" editableThingsListViewModel={editableThingsListViewModel} />
            <FormProvider {...formFunctions}>
                <BillingTransactionContext.Provider value={memoizedContextValues}>
                    <form
                        className="front-royal-form-container admin-edit-billing-transaction"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <TransactionTypeRow />
                        {isPayment(values) ? (
                            <>
                                <ProviderRow />
                                <ProviderTransactionIdRow />
                                {values.payment_method_category ? <PaymentMethodCategoryRow /> : null}
                            </>
                        ) : null}
                        <TransactionTimeRow />
                        <AmountRow />
                        <TuitionContractRow />
                        {isPayment(values) ? <RefundsRow /> : null}
                        <DescriptionRow />
                        <EditFormActionButtons
                            {...{
                                thing: billingTransaction,
                                editableThingsListViewModel,
                                disableDelete: hasUnselectableProvider(billingTransaction),
                                disableSave:
                                    hasUnselectableProvider(billingTransaction) &&
                                    !allowsManualRefunds(billingTransaction),
                            }}
                        />
                    </form>
                </BillingTransactionContext.Provider>
            </FormProvider>
        </ThemeProvider>
    );
}

export default AdminEditBillingTransaction;

import { useMemo } from 'react';
import { Divider } from '@mui/material';
import { useFormContext, type Path } from 'FrontRoyalReactHookForm';
import {
    type ProgramEntry,
    type AccessRules,
    type EditStudentNetworkFormValues,
    type InstitutionEntry,
} from 'AdminEditStudentNetworkEvent/AdminEditStudentNetworkEvent.types';
import TargetCohortSelect from './TargetCohortSelect';
import CheckboxThatUpdatesAccessRules from './CheckboxThatUpdatesAccessRules';
import InstitutionCheckbox from './InstitutionCheckbox';

function institutionGrantedAccess(accessRules: AccessRules, institutionEntry: InstitutionEntry) {
    return !!accessRules.targetInstitutionIds[institutionEntry.institutionId];
}

export default function InstitutionAccessRow({ institutionEntry }: { institutionEntry: InstitutionEntry }) {
    const { watch } = useFormContext<EditStudentNetworkFormValues>();
    const { accessRules } = watch();

    const programEntries = useMemo(
        () =>
            (Object.values(institutionEntry.programs) as ProgramEntry[]).sort((a, b) =>
                a.programTypeConfig.label > b.programTypeConfig.label ? 1 : -1,
            ),

        [institutionEntry],
    );

    return (
        <>
            <Divider />
            <div className="flex-row InstitutionAccessRow">
                <div>
                    <InstitutionCheckbox institutionEntry={institutionEntry} />
                </div>
                <div className="flex-column">
                    {programEntries.map(programEntry => (
                        <div key={programEntry.programType}>
                            <CheckboxThatUpdatesAccessRules
                                name={
                                    `accessRules.targetProgramTypes[${programEntry.programType}]` as Path<EditStudentNetworkFormValues>
                                }
                                label={`All ${programEntry.programTypeConfig.label} Cohorts`}
                                disabled={institutionGrantedAccess(accessRules, institutionEntry)}
                            />
                        </div>
                    ))}
                </div>
                <div>
                    <TargetCohortSelect
                        name={`accessRules.targetCohortIdsByInstitution.${institutionEntry.institutionId}`}
                        allCohortsForInstitution={institutionEntry.cohorts}
                        institutionEntry={institutionEntry}
                    />
                </div>
            </div>
        </>
    );
}

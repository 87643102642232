import { useContext } from 'react';
import BillingTransactionContext from './BillingTransactionContext';

function tuitionContractSelectOptions() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { owner } = useContext(BillingTransactionContext);
    const options = [
        {
            value: '',
            label: '- None -',
        },
    ];
    options.push(
        ...owner.programInclusions
            .filter(programInclusion => programInclusion.tuitionContractId)
            .map(programInclusion => ({
                value: programInclusion.tuitionContractId,
                label: programInclusion.cohortName,
            })),
    );

    return options;
}

export default tuitionContractSelectOptions;

import { type ReactNode, useContext } from 'react';
import { Table as MuiTable, TableBody, TableCell, TableHead, TableFooter, TableRow } from '@mui/material';
import Oreo from 'Oreo';
import AdminTableContext from './AdminTableContext';
import Pagination from './Pagination';
import Toolbar from './Toolbar';
import ColumnHeaders from './ColumnHeaders';
import { useSelectedFilterChips, SelectedFilterChips } from './SelectedFilterChips';

const baseTableRowStyle = {
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: `${Oreo.COLOR_V3_BEIGE_LIGHT}`,
    },
    opacity: '1',
};

export default function Table() {
    const { setRecordId, loading = false, reactTable } = useContext(AdminTableContext);
    const { headers = [], page = [], prepareRow = () => undefined } = reactTable || {};

    const selectedFilterChips = useSelectedFilterChips();

    return (
        <>
            <Toolbar />

            <MuiTable>
                <TableHead>
                    <TableRow>
                        <Pagination colSpan={headers.length} />
                    </TableRow>

                    {!!selectedFilterChips?.length && (
                        <TableRow>
                            <TableCell colSpan={headers.length}>
                                <SelectedFilterChips chips={selectedFilterChips} />
                            </TableCell>
                        </TableRow>
                    )}

                    <TableRow sx={loading ? { opacity: '0.3' } : undefined}>
                        <ColumnHeaders />
                    </TableRow>
                </TableHead>

                <TableBody>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <TableRow
                                key={row.original.id as string}
                                onClick={() => setRecordId?.(row.original.id as string)}
                                sx={[baseTableRowStyle, loading && { opacity: '0.3' }]}
                            >
                                {row.cells.map(cell => {
                                    const { key, role } = cell.getCellProps();
                                    return (
                                        <TableCell key={key} role={role}>
                                            {cell.render('Cell') as ReactNode}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>

                <TableFooter>
                    <TableRow>
                        <Pagination />
                    </TableRow>
                </TableFooter>
            </MuiTable>
        </>
    );
}

import { CardActions, Button, type ChipProps, Chip } from '@mui/material';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { type ProgramTypeConfig } from 'Program';
import { type CohortClass } from 'Cohorts';
import { useAngularContext } from 'AngularContext';
import {
    type EditStudentNetworkFormValues,
    type InstitutionEntry,
} from 'AdminEditStudentNetworkEvent/AdminEditStudentNetworkEvent.types';

export default function AccessSummary({
    expand,
    institutionEntries,
}: {
    expand: () => void;
    institutionEntries: InstitutionEntry[];
}) {
    const $injector = useAngularContext();
    const Cohort = $injector.get('Cohort') as CohortClass;
    const { watch } = useFormContext<EditStudentNetworkFormValues>();

    // Because of the way we're dynamicaly creating a set of checkboxes and assigning them nested
    // inside of accessRules, react-hook-form defines accessRules as a mutable object. That makes it
    // hard for us to memoize things in here. So, unfortunately, the list of chips is getting recalculated
    // on every render.
    const { accessRules } = watch();

    const chips: { key: string; label: string; color: ChipProps['color'] }[] = [];
    Object.keys(accessRules.targetInstitutionIds).forEach(institutionId => {
        const checked = accessRules.targetInstitutionIds[institutionId];
        if (checked) {
            const institutionEntry = institutionEntries.find(entry => entry.institutionId === institutionId);
            if (institutionEntry) {
                chips.push({
                    key: institutionEntry.institutionId,
                    label: `All ${institutionEntry.institutionName} cohorts`,
                    color: 'primary',
                });
            }
        }
    });

    Object.keys(accessRules.targetProgramTypes).forEach(programType => {
        const checked = accessRules.targetProgramTypes[programType];
        if (checked) {
            const programTypeConfig = Cohort.programTypes.find(
                (config: ProgramTypeConfig) => config.key === programType,
            );
            if (programTypeConfig) {
                chips.push({
                    key: programType,
                    label: `All ${programTypeConfig.label} cohorts`,
                    color: 'secondary',
                });
            }
        }
    });

    Object.keys(accessRules.targetCohortIdsByInstitution).forEach(institutionId => {
        const institutionEntry = institutionEntries.find(entry => entry.institutionId === institutionId);
        const cohorts = institutionEntry?.cohorts;
        const selectedCohortIds = accessRules.targetCohortIdsByInstitution[institutionId];
        if (!cohorts || !selectedCohortIds) return;
        const selectedCohorts = selectedCohortIds.map(cohortId => cohorts.find(c => c.id === cohortId)!);
        selectedCohorts
            .sort((a, b) => (a.startDate > b.startDate ? 1 : -1))
            .forEach(cohort => {
                chips.push({
                    key: cohort.id,
                    label: cohort.name,
                    color: 'default',
                });
            });
    });

    return (
        <div data-testid="AccessSummary">
            {chips.map(chip => (
                <Chip key={chip.key} label={chip.label} color={chip.color} variant="outlined" />
            ))}

            <CardActions>
                <Button onClick={expand}>Edit</Button>
            </CardActions>
        </div>
    );
}

import React, { useState } from 'react';
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Chip,
    Button,
    IconButton,
    Collapse,
    Tooltip,
} from '@mui/material';
import { CheckCircleOutline, EventAvailable, ExpandMore, ExpandLess, OpenInNew } from '@mui/icons-material';
import { type AdminStudentNetworkEvent } from 'StudentNetworkEvent';
import { formattedUserFacingDateTime } from 'DateHelpers';
import { getViewResultsLink } from './importUtils';

interface ViewResultsProps {
    title: string;
    importedEvents: AdminStudentNetworkEvent[];
}

const ViewResults: React.FC<ViewResultsProps> = ({ title, importedEvents }) => {
    const [expanded, setExpanded] = useState(false);
    const allEventsLink = getViewResultsLink(importedEvents.map(event => event.id));

    const toggleExpanded = () => setExpanded(!expanded);

    return (
        <Paper elevation={0} sx={{ my: 2, p: 3, backgroundColor: 'grey.50', borderRadius: 2 }}>
            {importedEvents.length > 0 && (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <CheckCircleOutline sx={{ color: 'success.main', mr: 2, fontSize: 32 }} />
                        <Typography variant="h5" component="h2">
                            {title || 'Import Successful'}
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                        <EventAvailable sx={{ mr: 1, color: 'text.secondary' }} />
                        <Typography variant="body1" color="textSecondary">
                            {importedEvents.length} event{importedEvents.length !== 1 && 's'} imported
                        </Typography>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                            onClick={toggleExpanded}
                        >
                            {expanded ? 'Hide' : 'Show'} Results
                        </Button>
                        {allEventsLink && (
                            <Button
                                variant="contained"
                                color="primary"
                                endIcon={<OpenInNew />}
                                href={allEventsLink}
                                target="_blank"
                                sx={{ ml: 2 }}
                            >
                                View All Imported Events
                            </Button>
                        )}
                    </Box>

                    <Collapse in={expanded}>
                        <Paper elevation={1} sx={{ overflow: 'hidden', mb: 2 }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: 'grey.100' }}>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Start Time</TableCell>
                                        <TableCell>Event Type</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {importedEvents.map(event => (
                                        <TableRow key={event.id} hover>
                                            <TableCell>{event.title}</TableCell>
                                            <TableCell>
                                                {event.start_time &&
                                                    formattedUserFacingDateTime(event.start_time * 1000)}
                                            </TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={event.event_type}
                                                    size="small"
                                                    color="primary"
                                                    variant="outlined"
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Tooltip title="Edit Event">
                                                    <IconButton
                                                        size="small"
                                                        color="primary"
                                                        href={`/admin/mba/student_network_events?studentnetworkevent-id=${event.id}`}
                                                        target="_blank"
                                                    >
                                                        <OpenInNew fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Collapse>
                </>
            )}

            {importedEvents.length === 0 && (
                <Typography variant="body1" color="text.secondary">
                    No events have been imported.
                </Typography>
            )}
        </Paper>
    );
};

export default ViewResults;

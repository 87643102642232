/* eslint-disable no-nested-ternary */
import { useContext } from 'react';
import { AttachMoney } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { ErrorMessage, FieldRow } from 'FrontRoyalForm';
import { useFormContext, useFieldArray } from 'FrontRoyalReactHookForm';
import { DatePicker, Select, TextField } from 'FrontRoyalMaterialUiForm';
import { PROVIDER_SVB, PROVIDER_PAYPAL, PROVIDER_STRIPE, allowsManualRefunds } from 'BillingTransaction';
import hasUnselectableProvider from 'BillingTransaction/hasUnselectableProvider';
import { providerOptionsForSelectInput } from 'BillingTransaction/helpers';
import moment from 'moment-timezone';
import BillingTransactionContext from './BillingTransactionContext';

const RefundsRow = () => {
    const { watch, control } = useFormContext();
    const [provider, refunds] = watch(['provider', 'refunds']);
    const { billingTransaction } = useContext(BillingTransactionContext);
    const { fields, append, remove } = useFieldArray({ control, name: 'refunds', keyName: 'key' });

    const refundsFields = fields.map((input, index) => ({ ...input, ...refunds[index] }));

    return (
        <FieldRow>
            {refundsFields?.map((refund, index) => (
                <RefundEditor key={refund.key} {...{ index, refund, remove, provider }} />
            ))}

            <ErrorMessage name="refunds" />

            <button
                className="flat blue auto-width"
                type="button"
                name="add-refund"
                disabled={!allowsManualRefunds(billingTransaction)}
                onClick={() => {
                    // If the billing transaction has a selectable provider (like paypal)
                    // then usually the refund will have the same provider, so we default to that.
                    // If the billing transaction has an unselectable provider (like stripe)
                    // then the admin is going to be required to choose a different provider for the refund.
                    // So don't default at all.
                    let defaultProvider;
                    if (!hasUnselectableProvider(billingTransaction)) {
                        defaultProvider = billingTransaction.provider;
                    }
                    append({ amount: '0', refund_time: moment(), provider: defaultProvider });
                }}
            >
                Add
                {/* For Stripe transactions (if we allow manual refunds) update the button text
                            to show we're adding a refund outside of the Stripe platform */}
                {provider === PROVIDER_STRIPE && allowsManualRefunds(billingTransaction) ? ' non-Stripe ' : ' '}
                Refund
            </button>
        </FieldRow>
    );
};

function RefundEditor({ refund, provider, remove, index }) {
    const { setFieldValue, trigger } = useFormContext();
    const selectProviders = providerOptionsForSelectInput(refund);
    return (
        <div className="refund-editor">
            <TextField
                name={`refunds[${index}].amount`}
                onChange={e => {
                    setFieldValue(`refunds[${index}].amount`, e.target.value);
                    trigger(['amount', 'refunds']);
                }}
                fullWidth
                disabled={hasUnselectableProvider(refund)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <AttachMoney />
                        </InputAdornment>
                    ),
                }}
            />

            <DatePicker
                label="Refund"
                name={`refunds[${index}].refund_time`}
                disabled={hasUnselectableProvider(refund)}
            />
            <TextField
                label="Provider Transaction ID"
                name={`refunds.${index}.provider_transaction_id`}
                disabled={hasUnselectableProvider(refund)}
                required
            />
            <Select
                label="Provider"
                name={`refunds.${index}.provider`}
                options={selectProviders}
                optionLabel={option => option.name}
                disabled={hasUnselectableProvider(refund)}
                required
            />

            {/* We could add an external link here, but it would only be relevant in the paypal case, and
             I don't think we have ever issued a paypal refund.  In the stripe case, refunds do not get their
             own page, so the link above to the billing transaction would be the link to follow.  In the SVB case,
             there are no links at all.
             */}
            <div className="sub-text note">
                {provider === PROVIDER_SVB ? (
                    <>
                        Use the <i>Case Number</i> for the refund.
                    </>
                ) : provider === PROVIDER_PAYPAL ? (
                    <>
                        Use the <i>Transaction ID</i>.
                    </>
                ) : provider === PROVIDER_STRIPE ? (
                    <>
                        If the refund is issued via a wire transfer, use &quot;wire-<i>[Case Number]</i>&quot;. If the
                        refund is issued via paypal, use &quot;paypal-<i>[Transaction ID]</i>&quot;.
                    </>
                ) : null}
            </div>
            <button
                type="button"
                className="flat red remove"
                onClick={() => remove(index)}
                disabled={hasUnselectableProvider(refund)}
            >
                <span className="sr-only">Remove refund</span>
                <i className="far fa-trash-alt" name="remove-item" />
            </button>
        </div>
    );
}

export default RefundsRow;

import { useContext, useMemo } from 'react';
import moment from 'moment';
import Papa from 'papaparse';
import { type Column, type Row } from 'react-table';
import { Link } from '@mui/material';
import AdminTableContext from './AdminTableContext';

type ColumnWithCSVInfo = Column & {
    CsvCell?: string;
};

const getFormattedColumnValue = (column: ColumnWithCSVInfo, row: Row): string => {
    const cellValue = column.id ? row.values[column.id] : '';

    if (column.CsvCell === 'time') {
        return moment(cellValue * 1000).format('MM/DD/YY HH:mm');
    }

    return cellValue;
};

export const tableToCSV = (data: { filePrefix?: string; table: { rows: Row[]; columns: ColumnWithCSVInfo[] } }) => {
    const { columns, rows } = data.table;

    const blob = new Blob(
        [
            Papa.unparse([
                columns.map((column: Column) => column.Header),
                ...rows.map((row: Row) =>
                    columns.map((column: ColumnWithCSVInfo) => getFormattedColumnValue(column, row)),
                ),
            ]),
        ],
        {
            type: 'data:text/csv;charset=utf-8',
        },
    );
    const url = window.URL.createObjectURL(blob);

    return {
        url,
        download: `${data.filePrefix || 'export'}-${moment(new Date()).format('YYYY.MM.DD.HH.mm')}.csv`,
    };
};

export default function CSVExport() {
    const { reactTable, klassName } = useContext(AdminTableContext);
    const { rows = [], columns = [] } = reactTable || {};
    const { url, download } = useMemo(
        () => tableToCSV({ filePrefix: klassName, table: { rows, columns } }),
        [klassName, rows, columns],
    );

    return (
        <Link
            color="secondary"
            underline="hover"
            href={url}
            download={download}
            onClick={() => {
                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                }, 2500);
            }}
        >
            Export as CSV
        </Link>
    );
}

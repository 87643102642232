import { useContext } from 'react';
import { Tooltip, Fab } from '@mui/material';
import { Add } from '@mui/icons-material';
import AdminTableContext from './AdminTableContext';

export default function CreateButton() {
    const { setRecordId } = useContext(AdminTableContext);

    return (
        <Tooltip title="Create New">
            <Fab
                color="secondary"
                aria-label="Create"
                onClick={() => setRecordId?.('create')}
                sx={{ flex: '0 0 auto' }}
            >
                <Add />
            </Fab>
        </Tooltip>
    );
}

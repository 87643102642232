import { useEffect, useState, useContext } from 'react';
import { Chip } from '@mui/material';
import { clone } from 'lodash/fp';
import AdminTableContext from './AdminTableContext';

const defaultOptionLabel = opt => (typeof opt === 'object' ? opt.label : opt);
const defaultOptionValue = opt => (typeof opt === 'object' ? opt.value : opt);

const getLabelFromProps = ({ options, optionLabel = defaultOptionLabel, optionValue = defaultOptionValue }, val) =>
    options?.length ? optionLabel(options.find(opt => optionValue(opt) === val)) : (!!val && String(val)) || undefined;

export function useSelectedFilterChips() {
    const { filters, filterFields } = useContext(AdminTableContext);
    const [selectedFilterChips, setSelectedFilterChips] = useState([]);

    useEffect(() => {
        const selected = [];

        filterFields
            .filter(({ hidden }) => !hidden)
            .forEach(({ name, getLabelsForSelectFilterChips, component }) => {
                const valueFromForm = filters[name];
                const values = Array.isArray(valueFromForm) ? valueFromForm : [valueFromForm];

                values.forEach(value => {
                    const label = getLabelsForSelectFilterChips
                        ? getLabelsForSelectFilterChips(value)
                        : getLabelFromProps({ ...component.props }, value);

                    if (!!label && !!value) selected.push({ value, name, label });
                });
            });

        setSelectedFilterChips(selected);
    }, [filterFields, filters]);

    return selectedFilterChips;
}

export function SelectedFilterChips({ chips }) {
    const { filters, setFilters, defaultFilters } = useContext(AdminTableContext);
    const newFilters = clone(filters);

    const removeFilter = ({ value, name }) => {
        if (Array.isArray(filters[name])) {
            newFilters[name] = filters[name].filter(val => val !== value);
        } else {
            newFilters[name] = defaultFilters[name];
        }
        setFilters(newFilters);
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {chips.map(selectedFilter => (
                <Chip
                    key={selectedFilter.value}
                    label={selectedFilter.label}
                    variant="outline"
                    color="primary"
                    onDelete={() => removeFilter(selectedFilter)}
                    sx={{ margin: '0 3px' }}
                />
            ))}
        </div>
    );
}

import { type CohortForAdminListsAttrs } from 'Cohorts';
import { type ProgramType } from 'Program';
import { type AdminStudentNetworkEvent } from 'StudentNetworkEvent';

export type PortedStudentNetworkEvent = Omit<
    AdminStudentNetworkEvent,
    | 'target_cohort_ids'
    | 'target_institution_ids'
    | 'target_program_types'
    | 'target_student_statuses'
    | 'place_details'
    | 'image'
> & {
    target_cohort_ids: string | string[];
    target_institution_ids: string | string[];
    target_program_types: string | ProgramType[];
    target_student_statuses: string | string[];
    place_details: string;
    image: { formats: { original: { url: string } } };
    extra_export_data?: {
        selected_cohort: string;
        other_targeted_cohorts: string;
    };
};

export enum StudentNetworkEventCSVHeaders {
    id = 'Event ID',
    selectedCohort = 'Selected Cohort',
    otherTargetedCohorts = 'Other Targeted Cohorts',
    title = 'Title',
    event_type = 'Event Type',
    start_time = 'Start Time',
    end_time = 'End Time',
    timezone = 'Timezone',
    cohortStartDate = 'Cohort Start Date',
    cohortStartToEventStart = 'Days from Cohort',
    date_tbd = 'Date TBD',
    date_tbd_description = 'Date TBD Description',
    description = 'Description',
    location = 'Location',
    location_name = 'Location Name',
    place_id = 'Place ID',
    place_details = 'Place Details',
    rsvp_status = 'RSVP Status',
    external_rsvp_url = 'External RSVP URL',
    internal_notes = 'Internal Notes',
    image_id = 'Image ID',
    image_url = 'Image URL',
    target_cohort_ids = 'Target Cohort IDs',
    target_institution_ids = 'Target Institution IDs',
    target_program_types = 'Target Program Types',
    target_student_statuses = 'Target Student Statuses',
    published = 'Published',
    featured = 'Featured',
}

export type StudentNetworkEventCSVColumn = {
    id: keyof typeof StudentNetworkEventCSVHeaders;
    Header?: typeof StudentNetworkEventCSVHeaders[keyof typeof StudentNetworkEventCSVHeaders];
    accessor?: keyof PortedStudentNetworkEvent | ((row: PortedStudentNetworkEvent) => string | null | undefined);
};

export type StudentNetworkEventCSVColumns = StudentNetworkEventCSVColumn[];

export interface ExportMessage {
    severity?: 'error' | 'warning' | 'info' | 'success';
    message?: React.ReactNode;
}

export type ImportData = {
    recordsToImport: PortedStudentNetworkEvent[];
    importOption: 'as-is' | 'override';
    selectedCohorts: CohortForAdminListsAttrs[] | [];
    targetStudentStatuses: string[];
};

export type ImportHandlerStep = {
    label: string;
    description?: string | undefined;
};

export type ImportHandlerSteps = Record<string, ImportHandlerStep>;

export interface ImportButtonRef {
    setDisabled: (disabled: boolean) => void;
    setOnClick: (onClick: () => void) => void;
}

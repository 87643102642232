import { useContext } from 'react';
import { Breadcrumbs, Typography, Link } from '@mui/material';
import { NavigateNext, Home } from '@mui/icons-material';
import { theme } from 'FrontRoyalMaterialUiForm';
import AdminTableContext from './AdminTableContext';

type BackButtonProps = { home: string; detail: string };

export const BackButton = ({ home, detail }: BackButtonProps) => {
    const { setRecordId } = useContext(AdminTableContext);

    return (
        <div style={{ padding: theme.spacing(1, 2), marginBottom: 20 }}>
            <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                <Link
                    component="button"
                    color="secondary"
                    underline="hover"
                    onClick={() => setRecordId?.(null)}
                    style={{ display: 'flex' }}
                >
                    <Home style={{ marginRight: theme.spacing(0.5), width: 20, height: 20 }} />
                    {home}
                </Link>
                <Typography color="textPrimary">{detail}</Typography>
            </Breadcrumbs>
        </div>
    );
};

export default BackButton;

import { useContext } from 'react';
import { TextField } from '@mui/material';
import AdminTableContext from './AdminTableContext';

export default function QuickFilter() {
    const { quickFilterValue, setQuickFilterValue } = useContext(AdminTableContext);

    return setQuickFilterValue ? (
        <TextField
            onChange={e => setQuickFilterValue(e.target.value)}
            label="Quick Filter"
            value={quickFilterValue}
            size="small"
            style={{ maxWidth: '280px', flex: 'initial' }}
        />
    ) : null;
}

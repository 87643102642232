import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { MaterialUiPopoverButton } from 'FrontRoyalMaterialUi';
import AdminTableContext from './AdminTableContext';
import FilterForm from './FilterForm';

export const FilterButton = () => {
    const { setFilters = () => undefined, filterFields = [], filters = {} } = useContext(AdminTableContext);
    const [open, setOpen] = useState(false);

    return filterFields.length > 0 ? (
        <div style={{ display: 'inline' }}>
            <MaterialUiPopoverButton
                title="Filter list"
                label="Filter list"
                icon={<FontAwesomeIcon icon={faFilter} />}
                open={open}
                setOpen={setOpen}
            >
                <div style={{ maxWidth: 400, padding: 12, paddingTop: 20 }}>
                    <FilterForm
                        {...{ filterFields, filters }}
                        onSubmit={values => {
                            setFilters?.(values);
                            setOpen(false);
                        }}
                    />
                </div>
            </MaterialUiPopoverButton>
        </div>
    ) : null;
};

export default FilterButton;

import { useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';
import getFilterFieldDefaultValue from './getFilterFieldDefaultValue';
import { type Filters, type FilterField } from './AdminTable.types';

interface Args {
    filterFields: FilterField[];
    collection: string;
}

interface ReturnValue {
    setFilters: (filters: Filters) => void;
    filters: Filters | undefined;
    defaultFilters: Filters | undefined;
}

export default function useFilters({ filterFields, collection }: Args): ReturnValue {
    const [filters, _setFilters] = useState<Filters>();
    const [storedFilterJson, setStoredFilterJson] = useLocalStorage<string>(`${collection}-filters`);
    const [defaultFilters, setDefaultFilters] = useState<Filters>();

    useEffect(() => {
        let storedFilters: Filters | undefined;

        if (storedFilterJson) {
            storedFilters = JSON.parse(storedFilterJson);
        }

        const _defaultFilters: Filters = {};
        filterFields.forEach((filterField: FilterField) => {
            _defaultFilters[filterField.name] = getFilterFieldDefaultValue(filterField);
        });

        setDefaultFilters(_defaultFilters);

        _setFilters(storedFilters || _defaultFilters);
    }, [filterFields, storedFilterJson]);

    // We prefix the actual setter because that should only
    // be called in this file.  Anything external should use
    // this setFilters function to set the filters through local storage.
    function setFilters(newFilters: Filters) {
        setStoredFilterJson(JSON.stringify(newFilters));
    }

    return {
        setFilters,
        filters,
        defaultFilters,
    };
}

import { useState, useCallback } from 'react';
import { CircularProgress, Grid, IconButton, Tooltip } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';
import { type CohortForAdminListsAttrs } from 'Cohorts';
import { type StudentNetworkInstance } from 'StudentNetwork/StudentNetwork.types';
import { useAngularContext } from 'AngularContext';
import { SnackbarAlert } from 'FrontRoyalMaterialUi/SnackbarAlert';
import { type ExportMessage } from '../AdminCohortEventsPorter.types';
import HierarchicalCohortAutocomplete from '../HierarchicalCohortAutocomplete';
import { fetchEventsForCohort } from '../eventDataHelpers';
import { filterAndExportEvents } from './exportUtils';

interface ExportHandlerProps {
    cohorts: CohortForAdminListsAttrs[] | [];
}

const ExportHandler: React.FC<ExportHandlerProps> = ({ cohorts }) => {
    const $injector = useAngularContext();
    const StudentNetworkEvent = $injector.get('StudentNetworkEvent');

    const [selectedCohort, setSelectedCohort] = useState<CohortForAdminListsAttrs | null>(null);
    const [exporting, setExporting] = useState<boolean>(false);
    const [exportMessage, setExportMessage] = useState<ExportMessage>({});

    const handleExportSubmit = useCallback(() => {
        if (selectedCohort) {
            setExporting(true);
            setExportMessage({ severity: 'info', message: 'Exporting events...' });

            fetchEventsForCohort({
                StudentNetworkEvent: StudentNetworkEvent as StudentNetworkInstance,
                selectedCohortId: selectedCohort.id,
            })
                .then(eventsForExport => {
                    if (eventsForExport.length === 0) {
                        setExportMessage({
                            severity: 'warning',
                            message: `No events found for ${selectedCohort.name}`,
                        });
                        setExporting(false);
                        return;
                    }
                    filterAndExportEvents(eventsForExport, selectedCohort, cohorts, setExporting, setExportMessage);
                })
                .catch(e => {
                    setExportMessage({ severity: 'error', message: `Error exporting events: ${e.message}` });
                    setExporting(false);
                });
        }
    }, [selectedCohort, cohorts, StudentNetworkEvent]);

    const handleSnackbarClose = useCallback(() => {
        setExportMessage({});
    }, []);

    return (
        <Grid container spacing={2} alignItems="center" data-testid="export-handler">
            <Grid item xs>
                <HierarchicalCohortAutocomplete
                    cohorts={cohorts}
                    onChange={cohort => setSelectedCohort(cohort as CohortForAdminListsAttrs)}
                    value={selectedCohort}
                    multiple={false}
                    size="small"
                />
            </Grid>
            <Grid item>
                <Tooltip title="Export Events">
                    <span>
                        <IconButton
                            onClick={handleExportSubmit}
                            color={exportMessage.severity || 'primary'}
                            disabled={exporting || !selectedCohort}
                            data-testid="export-button"
                        >
                            {exporting ? <CircularProgress size={24} /> : <CloudDownload />}
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            <SnackbarAlert
                message={exportMessage.message || ''}
                severity={exportMessage.severity || 'info'}
                open={!!exportMessage.message}
                onClose={handleSnackbarClose}
                duration={exportMessage.severity === 'success' ? 6000 : undefined}
            />
        </Grid>
    );
};

export default ExportHandler;

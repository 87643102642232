import { type ReactNode, useContext, useMemo } from 'react';
import { Button } from '@mui/material';
import { Flex } from 'FrontRoyalMaterialUi';
import { FormProvider, useForm } from 'FrontRoyalReactHookForm';
import AdminTableContext from './AdminTableContext';
import { type FilterField, type Filters } from './AdminTable.types';

const FlexChild = ({ id, children }: { id?: string; children: ReactNode }) => (
    <div id={id} style={{ minWidth: '120px', paddingBottom: '12px', flexGrow: '1', width: '100%' }}>
        {children}
    </div>
);

type FilterFormProps = {
    filters: Filters;
    filterFields: FilterField[];
    onSubmit: (values: Filters) => void;
};
export const FilterForm = ({ filterFields, filters, onSubmit }: FilterFormProps) => {
    const { defaultFilters = {} } = useContext(AdminTableContext);
    const formFunctions = useForm({ defaultValues: filters, mode: 'all', reValidateMode: 'onChange' });
    const visibleFilterFields = useMemo(() => filterFields.filter(field => !field.hidden), [filterFields]);

    const {
        formState: { isValid, isSubmitting },
        reset,
    } = formFunctions;

    return (
        <FormProvider {...formFunctions}>
            <form onSubmit={formFunctions.handleSubmit(onSubmit)}>
                <Flex flexWrap="wrap" alignItems="flex-start" alignContent="flex-start">
                    {visibleFilterFields?.map(({ name, component }) => (
                        <FlexChild id={name} key={name}>
                            {component}
                        </FlexChild>
                    ))}
                </Flex>

                <Flex justifyContent="space-between">
                    <Flex flex="0 1 0">
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={isSubmitting}
                            onClick={() => {
                                reset(defaultFilters);
                            }}
                        >
                            Reset
                        </Button>
                    </Flex>

                    <Flex flex="0 1 0">
                        <Button variant="contained" type="submit" color="primary" disabled={!isValid || isSubmitting}>
                            Filter
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </FormProvider>
    );
};

export default FilterForm;

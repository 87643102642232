import { type ReactNode, useContext } from 'react';
import { TableCell, TableSortLabel } from '@mui/material';
import { type ColumnInstance } from 'react-table';
import { type AnyObject } from '@Types';
import AdminTableContext from './AdminTableContext';

const disabledTableSX = {
    cursor: 'auto',
    '&:hover': {
        color: 'inherit',
    },
    '&:hover .MuiTableSortLabel-icon': {
        opacity: 0,
    },
};

const sortDirection = (column: ColumnInstance<AnyObject>) =>
    column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : undefined;

export default function ColumnHeaders() {
    const { loading: disabled, reactTable } = useContext(AdminTableContext);
    const { headers } = reactTable || {};

    return headers ? (
        <>
            {headers.map(column => (
                <TableCell
                    {...column.getHeaderProps(disabled ? undefined : column.getSortByToggleProps())}
                    component="th"
                    sortDirection={sortDirection(column)}
                >
                    <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                        sx={disabled ? disabledTableSX : {}}
                    >
                        {column.render('Header') as ReactNode}
                    </TableSortLabel>
                </TableCell>
            ))}
        </>
    ) : null;
}

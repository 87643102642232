import moment from 'moment';
import { type ProgramType } from 'Program';
import { type AdminStudentNetworkEvent, type EventType, type StudentStatus } from 'StudentNetworkEvent';

export const mapEventRecords = (eventData: Record<string, string>[]) => {
    const dateTimeToUnix = (date: string) => (date ? moment(date, 'MM/DD/YY HH:mm').unix() : undefined);
    const strToArr = (str: string) => (str ? str.split(',').map(id => id.trim()) : []);

    return eventData.map(row => {
        const event: Partial<AdminStudentNetworkEvent> = {
            title: row.title,
            event_type: row.event_type as EventType,
            start_time: dateTimeToUnix(row.start_time),
            end_time: dateTimeToUnix(row.end_time),
            timezone: row.timezone,
            date_tbd: ['true', true].includes(row.date_tbd),
            date_tbd_description: row.date_tbd_description,
            description: row.description,
            location_name: row.location_name,
            place_id: row.place_id,
            place_details: JSON.parse(row.place_details || '{}'),
            rsvp_status: row.rsvp_status as 'not_required' | 'required' | 'closed',
            external_rsvp_url: row.external_rsvp_url,
            internal_notes: row.internal_notes,
            image_id: row.image_id,
            target_cohort_ids: strToArr(row.target_cohort_ids),
            target_institution_ids: strToArr(row.target_institution_ids),
            target_program_types: strToArr(row.target_program_types) as ProgramType[],
            target_student_statuses: strToArr(row.target_student_statuses) as StudentStatus[],
            published: false,
        };

        return event;
    });
};

export const getViewResultsLink = (event_ids: string[]) => {
    if (event_ids.length > 0) {
        const filters = JSON.stringify({
            id: event_ids,
            editor: true,
            start_time: 0,
            end_time: 4070930400,
            include_tbd: true,
        });

        return `/admin/mba/student_network_events?filters=${encodeURIComponent(filters)}&fields=ADMIN_FIELDS`;
    }
    return null;
};

import { useContext } from 'react';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import AdminTableContext from './AdminTableContext';
import CreateButton from './CreateButton';
import FilterButton from './FilterButton';
import QuickFilter from './QuickFilter';
import CSVExport from './CSVExport';
import { type ToolbarAction } from './AdminTable.types';

const Toolbar: React.FC = () => {
    const { showQuickFilter, showCSVExport, customToolbarActions } = useContext(AdminTableContext);

    const staticActions = [
        { ...(<CreateButton />), key: 'create' } as ToolbarAction,
        { ...(<FilterButton />), key: 'filter' } as ToolbarAction,
    ];

    const optionalActions = [
        showQuickFilter && ({ ...(<QuickFilter />), key: 'quickFilter' } as ToolbarAction),
        showCSVExport && ({ ...(<CSVExport />), key: 'csvExport' } as ToolbarAction),
    ].filter(Boolean);

    const customActions = customToolbarActions as ToolbarAction[];

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));

    return (
        <>
            {isXs ? (
                <Grid container spacing={3} padding={3} display="flex">
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            {[...staticActions, ...optionalActions].map(
                                action => action && <Box key={`action-${action.key}`}>{action}</Box>,
                            )}
                        </Box>
                    </Grid>
                    {customActions.length > 0 && (
                        <Grid item xs={12}>
                            {customActions.map((action, index) => (
                                <Box key={`customAction-${action?.key || index}`}>{action}</Box>
                            ))}
                        </Grid>
                    )}
                </Grid>
            ) : (
                <Grid container spacing={2} padding={2} wrap="nowrap" gridTemplateColumns="auto 1fr">
                    <Grid item container sm={6} lg={7} alignItems="center">
                        {[...staticActions, ...optionalActions].map(
                            action =>
                                action && (
                                    <Grid item key={`action-${action.key}`}>
                                        {action}
                                    </Grid>
                                ),
                        )}
                    </Grid>
                    {customActions.length > 0 && (
                        <Grid item container sm={6} lg={5} alignItems="center" justifyContent="flex-end">
                            {customActions.map(
                                action =>
                                    action && (
                                        <Grid
                                            item
                                            key={`action-${action.key}`}
                                            display="flex"
                                            flex="1"
                                            justifyContent="flex-end"
                                        >
                                            {action}
                                        </Grid>
                                    ),
                            )}
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
};
export default Toolbar;

import { useRef, useEffect, useState, useMemo } from 'react';
import AdminEditStudentNetworkEvent from 'AdminEditStudentNetworkEvent';
import { TextField, Select, MultiLocationInput, theme } from 'FrontRoyalMaterialUiForm';
import AdminTable, { CheckmarkCell, TimeCell } from 'AdminTable';
import { EVENT_TYPE_CONFIGS, eventTypeIcon } from 'StudentNetworkEvent';
import { omit } from 'lodash/fp';
import Oreo from 'Oreo';
import { useTranslation } from 'react-i18next';
import { useAngularContext } from 'AngularContext';
import AdminCohortEventsPorter from 'AdminCohortEventsPorter';

const titleCell = function Title({ row: { original } }) {
    const { image, title } = original;
    const src = image?.formats?.original?.url;

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                maxWidth: '600px',
            }}
        >
            {src && (
                <div
                    style={{
                        backgroundColor: Oreo.COLOR_V3_MAP_BLUE_MID,
                        width: 40,
                        height: 30,
                        borderRadius: 3,
                        marginRight: 10,
                        overflow: 'hidden',
                        flexShrink: 0,
                    }}
                >
                    <img
                        src={src}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        alt="title"
                    />
                </div>
            )}
            <span>{title}</span>
        </div>
    );
};

const eventTypeCell = function EventType({ row: { original } }) {
    const { t } = useTranslation('back_royal');
    const eventType = original.event_type;
    const { src, width, height } = eventTypeIcon(eventType);
    return (
        <span style={{ whiteSpace: 'nowrap' }}>
            <img src={src} width={width} height={height} style={{ marginRight: theme.spacing() }} alt="event-type" />
            {t(`student_network.student_network_event.${eventType}`)}
        </span>
    );
};

const cityCell = function City({ row: { original } }) {
    return <>{stringForPlace(original)}</>;
};

function stringForPlace(studentNetworkEvent) {
    if (!studentNetworkEvent.placeCity) {
        return '';
    }
    return `${studentNetworkEvent.placeCity}${studentNetworkEvent.placeCity && ', '}${
        studentNetworkEvent.placeCountry
    }`;
}

const paddingLeftFromOutlinedInput = 14;

const eventTypeRenderValueStyle = {
    position: 'absolute',
    top: '50%',

    // the `left` value here should be the same as the
    left: paddingLeftFromOutlinedInput,
    transform: 'translateY(-50%)',
    width: `calc(100% - ${2 * paddingLeftFromOutlinedInput}px)`,
    overflow: 'hidden',
};

export default function AdminStudentNetworkEventsTable() {
    const { t } = useTranslation('back_royal');
    const $injector = useAngularContext();
    const [cohortOptions, setCohortOptions] = useState([]);
    const $location = $injector.get('$location');

    const columns = useRef([
        {
            Header: 'Title',
            accessor: 'title',
            Cell: titleCell,
        },
        {
            Header: 'Start',
            accessor: 'start_time',
            Cell: TimeCell,
        },
        {
            Header: 'Type',
            accessor: 'event_type',
            Cell: eventTypeCell,
        },
        {
            Header: 'Location Name',
            accessor: 'location_name',
        },
        {
            Header: 'City',
            accessor: 'place_details',
            Cell: cityCell,
            sortType(a, b) {
                const strA = stringForPlace(a.original);
                const strB = stringForPlace(b.original);
                if (strA > strB) {
                    return 1;
                }
                if (strA < strB) {
                    return -1;
                }
                return 0;
            },
        },
        {
            Header: 'Published',
            accessor: 'published',
            Cell: CheckmarkCell,
        },
        {
            Header: 'Author',
            accessor: 'author_name',
        },
    ]).current;

    const filterFields = useMemo(
        () => [
            {
                name: 'keyword_search',
                component: <TextField label="Search" name="keyword_search" fullWidth />,
            },
            {
                name: 'event_type',
                component: (
                    <Select
                        name="event_type"
                        fullWidth
                        label="Type"
                        multiple
                        options={EVENT_TYPE_CONFIGS}
                        optionValue={opt => opt.key}
                        optionLabel={opt => t(`student_network.student_network_event.${opt.key}`)}
                        renderValue={opts => (
                            <>
                                {/* The nbsp is necessary to give the element one line of height */}
                                &nbsp;
                                {/* Then we absolutely position the icons so that their height
                        does not effect the height of the element */}
                                <div style={eventTypeRenderValueStyle}>
                                    {opts.map(key => {
                                        const { src, height, width } = eventTypeIcon(key);
                                        return <img key={key} src={src} width={width} height={height} alt="event" />;
                                    })}
                                </div>
                            </>
                        )}
                    />
                ),

                default: [],
            },
            {
                name: 'published',
                component: (
                    <Select
                        fullWidth
                        options={[
                            { label: 'All', value: '' },
                            { label: 'Published', value: true },
                            { label: 'Unpublished', value: false },
                        ]}
                        label="Published"
                        name="published"
                    />
                ),
            },
            {
                name: 'time_filter',
                component: (
                    <Select
                        fullWidth
                        options={[
                            { label: 'All', value: '' },
                            { label: 'Upcoming Events', value: 'upcoming' },
                            { label: 'Past Events', value: 'past' },
                            { label: 'TBD', value: 'tbd' },
                        ]}
                        label="Time Filter"
                        name="time_filter"
                    />
                ),
            },
            {
                name: 'places',
                label: 'Locations',
                component: <MultiLocationInput name="places" label="Locations" fullWidth />,
                getLabelsForSelectFilterChips: placeDetails => placeDetails?.formatted_address,
                default: [],
            },
            {
                name: 'cohort',
                component: <Select options={cohortOptions} label="Cohort" name="cohort" fullWidth />,
            },
            {
                name: 'editor',
                hidden: true,
                default: true,
            },
        ],
        [cohortOptions, t],
    );

    const StudentNetworkEvent = $injector.get('StudentNetworkEvent');
    const Cohort = $injector.get('Cohort');

    useEffect(() => {
        let canceled = false;
        Cohort.index({
            fields: ['BASIC_FIELDS'],
        }).then(response => {
            if (canceled) {
                return;
            }
            const cohortOptionsResult = response.result.map(cohort => ({ label: cohort.name, value: cohort.id }));
            setCohortOptions(cohortOptionsResult);
        });

        return () => {
            canceled = true;
        };
    }, [$injector, Cohort]);

    const getInitialFilters = () => {
        const filtersParam = $location.search().filters;
        if (filtersParam) {
            try {
                return JSON.parse(filtersParam);
            } catch {
                return {};
            }
        }
        return {};
    };

    const fetchData = useRef(requestDetails => {
        const queryFilters = omit(['start_time', 'end_time', 'include_tbd', 'only_tbd'])({
            ...requestDetails.filters,
            ...getInitialFilters(),
        });

        // Tricky: handle our custom Time Filter here
        if (queryFilters.time_filter === 'past') {
            queryFilters.start_time = 0;
            queryFilters.end_time = new Date().getTime() / 1000;
        } else if (queryFilters.time_filter === 'upcoming') {
            queryFilters.start_time = new Date().getTime() / 1000;
            queryFilters.end_time = new Date('2099/01/01').getTime() / 1000;
            queryFilters.include_tbd = true;
        } else if (queryFilters.time_filter === 'tbd') {
            queryFilters.only_tbd = true;
        } else {
            queryFilters.start_time = 0;
            queryFilters.end_time = new Date('2099/01/01').getTime() / 1000;
            queryFilters.include_tbd = true;
        }

        return StudentNetworkEvent.index({
            fields: ['ADMIN_FIELDS'],
            filters: queryFilters,
            limit: requestDetails.limit,
            offset: requestDetails.offset,
            sort: Array(requestDetails.sort),
            direction: requestDetails.direction,
        });
    }).current;

    const getBlankRecord = useRef(() => StudentNetworkEvent.new()).current;

    return (
        <AdminTable
            {...{
                idParam: 'studentnetworkevent-id',
                klassName: 'StudentNetworkEvent',
                columns,
                fetchData,
                filterFields,
                EditComponent: AdminEditStudentNetworkEvent,
                getBlankRecord,
                serverPaginationAndSorting: true,
                customToolbarActions: [<AdminCohortEventsPorter />],
                initialFilters: getInitialFilters(),
            }}
        />
    );
}

import { useContext } from 'react';
import { hasUnselectableProvider } from 'BillingTransaction';
import BillingTransactionContext from './BillingTransactionContext';

function disabledIfUnselectableProvider() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { billingTransaction } = useContext(BillingTransactionContext);
    return {
        disabled: hasUnselectableProvider(billingTransaction),
    };
}

export default disabledIfUnselectableProvider;

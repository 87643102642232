import { useContext, useEffect, useState, type ChangeEvent, type MouseEvent } from 'react';

import { type TableInstance } from 'react-table';
import { TablePagination, IconButton } from '@mui/material';
import { type TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material';
import { theme } from 'FrontRoyalMaterialUiForm';
import { type AnyObject } from '@Types';
import AdminTableContext from './AdminTableContext';

type PageChangeHandler = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
type RowsPerPageHandler = (event: ChangeEvent<HTMLInputElement>) => void;

function TablePaginationActions({ count, page, rowsPerPage, onPageChange }: TablePaginationActionsProps) {
    const { loading: disabled } = useContext(AdminTableContext);

    return (
        <div style={{ flexShrink: 0, marginLeft: '20px' }}>
            <IconButton
                onClick={event => onPageChange(event, 0)}
                disabled={disabled || page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton
                onClick={event => onPageChange(event, page - 1)}
                disabled={disabled || page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={event => onPageChange(event, page + 1)}
                disabled={disabled || page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
        </div>
    );
}

export default function Pagination({ colSpan }: { colSpan?: number }) {
    const { totalCount, reactTable, loading: disabled, quickFilterValue = '' } = useContext(AdminTableContext);
    const {
        state: { pageIndex, pageSize },
        gotoPage,
        setPageSize,
        rows = [],
    } = reactTable as TableInstance<AnyObject>;

    const [count, setCount] = useState(totalCount ?? 0);

    useEffect(() => {
        setCount(quickFilterValue.length > 0 ? rows.length : totalCount ?? 0);
    }, [rows, quickFilterValue, setCount, totalCount]);

    const handleChangePage: PageChangeHandler = (_event, newPage) => {
        gotoPage(newPage);
    };

    const handleRowsPerPagePage: RowsPerPageHandler = event => {
        setPageSize(parseInt(event.target.value, 10));
        gotoPage(0);
    };

    return (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={colSpan}
            count={count}
            rowsPerPage={pageSize}
            page={pageIndex}
            SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
                disabled,
            }}
            sx={{
                '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                    margin: 'unset',
                },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPagePage}
            ActionsComponent={TablePaginationActions}
        />
    );
}

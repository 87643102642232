import React, { useState, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import Papa from 'papaparse';
import { FileDropzone, type ImportedFileState, type ImportedCSVFileState } from 'FrontRoyalMaterialUiForm';

interface HandleCSVProps {
    title: string;
    onFilesProcessed: (processedFiles: ImportedCSVFileState[]) => void;
    initialFiles: ImportedCSVFileState[];
}

const HandleCSV: React.FC<HandleCSVProps> = ({ title, onFilesProcessed, initialFiles }) => {
    const [files, setFiles] = useState<ImportedCSVFileState[]>(initialFiles);

    const processFile = useCallback(
        (fileState: ImportedFileState): Promise<ImportedCSVFileState> =>
            new Promise(resolve => {
                Papa.parse(fileState.file, {
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    transformHeader: header => header.trim().toLowerCase().replace(/ /g, '_'),
                    complete: async parseResult => {
                        if (parseResult.errors.length > 0) {
                            resolve({
                                ...fileState,
                                status: 'error',
                                message: 'Error parsing file',
                                rows: [],
                            });
                        } else if (parseResult.data.length === 0) {
                            resolve({
                                ...fileState,
                                status: 'error',
                                message: 'No data found in file',
                                rows: [],
                            });
                        } else {
                            resolve({
                                ...fileState,
                                status: 'success',
                                message: `${parseResult.data.length} events`,
                                rows: parseResult.data as Record<string, string>[],
                            });
                        }
                    },
                });
            }),
        [],
    );

    const handleFileAdded = useCallback(
        async (fileState: ImportedFileState) => {
            const processedFile = await processFile(fileState);
            setFiles(prevFiles => {
                const updatedFiles = prevFiles.filter(f => f.id !== fileState.id);
                updatedFiles.push(processedFile);
                onFilesProcessed(updatedFiles);
                return updatedFiles;
            });
            return processedFile;
        },
        [processFile, onFilesProcessed],
    );

    const handleFileRemoved = useCallback(
        (fileId: string) => {
            setFiles(prevFiles => {
                const updatedFiles = prevFiles.filter(f => f.id !== fileId);
                onFilesProcessed(updatedFiles);
                return updatedFiles;
            });
        },
        [onFilesProcessed],
    );

    const handleClearFiles = useCallback(() => {
        setFiles([]);
        onFilesProcessed([]);
    }, [onFilesProcessed]);

    return (
        <Box sx={{ mt: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" gutterBottom>
                {title || 'Select CSV files'}
            </Typography>
            <FileDropzone
                initialFiles={files}
                accept={['.csv']}
                multiple
                onFileAdded={handleFileAdded}
                onFileRemoved={handleFileRemoved}
                onClearFiles={handleClearFiles}
                sx={{ marginTop: 2, width: '100%' }}
            />
        </Box>
    );
};

export default HandleCSV;

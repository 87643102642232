/* eslint-disable no-nested-ternary */
import { useEffect, useMemo, useState } from 'react';
import { Card, CardContent, CardActions, Button, Typography, InputLabel } from '@mui/material';
import { useCohorts } from 'UserAdministrationTab/redux';
import { useFormContext } from 'FrontRoyalReactHookForm';
import { type BrandOrInstitutionId, fetchBrandConfig } from 'AppBranding';
import { type ProgramType } from 'Program';
import { type CohortClass, type CohortForAdminListsAttrs } from 'Cohorts';
import { useAngularContext } from 'AngularContext';
import { AccessSummary, InstitutionAccessRow } from './AccessFieldsHelpers';
import { type EditStudentNetworkFormValues, type InstitutionEntry } from './AdminEditStudentNetworkEvent.types';
import './StudentNetworkEventAccessFields.scss';

type InstitutionEntryMap = Partial<Record<BrandOrInstitutionId, InstitutionEntry>>;

// The InstitutionEntry organizes the programs and cohorts by institution, which is how they
// get rendered on the screen.
function buildInstitutionEntries(Cohort: CohortClass, cohorts: CohortForAdminListsAttrs[] | undefined) {
    if (!cohorts) {
        return null;
    }
    const programTypeConfigs = Cohort.programTypes.filter(({ supportsNetworkAccess }) => supportsNetworkAccess);

    const map: InstitutionEntryMap = {};
    programTypeConfigs.forEach(programTypeConfig => {
        const programType = programTypeConfig.key;
        const institutionId = programTypeConfig.institutionID;
        if (!map[institutionId]) {
            map[institutionId] = {
                institutionId,
                institutionName: fetchBrandConfig(institutionId).brandNameShort,
                programs: {},
                programTypes: [] as ProgramType[],
                cohorts: cohorts.filter(
                    cohort => Cohort.institutionID(cohort.programType) === institutionId && !cohort.isolatedNetwork,
                ),
            };
        }
        const institutionEntry = map[institutionId]!;
        const programsMap = institutionEntry.programs;

        if (!programsMap[programType]) {
            programsMap[programType] = {
                programType,
                programTypeConfig,
            };
            institutionEntry.programTypes.push(programType);
        }
    });
    return (Object.values(map) as InstitutionEntry[]).sort((a, b) => (a.institutionName > b.institutionName ? 1 : -1));
}

function FormFields({
    institutionEntries,
    collapse,
}: {
    institutionEntries: InstitutionEntry[];
    collapse: () => void;
}) {
    const headerVariant = 'h6';
    return (
        <>
            <div className="flex-row header-row">
                <Typography variant={headerVariant}>Institution</Typography>
                <Typography variant={headerVariant}>Program</Typography>
                <Typography variant={headerVariant}>Cohort</Typography>
            </div>
            {institutionEntries.map(institutionEntry => (
                <InstitutionAccessRow institutionEntry={institutionEntry} key={institutionEntry.institutionId} />
            ))}
            <CardActions>
                <Button onClick={collapse}>Hide</Button>
            </CardActions>
        </>
    );
}

// If no access rules have been selected, then the user needs to select
// something, so expand the card by default.
function useExpandIfNoAccessRulesSelected(setExpanded: (val: boolean) => void) {
    const { watch } = useFormContext<EditStudentNetworkFormValues>();
    const { target_institution_ids, target_program_types, target_cohort_ids } = watch();
    useEffect(() => {
        if (
            target_institution_ids?.length === 0 &&
            target_program_types?.length === 0 &&
            target_cohort_ids?.length === 0
        ) {
            setExpanded(true);
        }
    }, [target_institution_ids, target_program_types, target_cohort_ids, setExpanded]);
}

export default function StudentNetworkEventAccessFields() {
    const [expanded, setExpanded] = useState(false);
    const { availableCohorts: cohorts } = useCohorts();
    const $injector = useAngularContext();
    const Cohort = $injector.get('Cohort') as CohortClass;
    useExpandIfNoAccessRulesSelected(setExpanded);
    const institutionEntries = useMemo(() => buildInstitutionEntries(Cohort, cohorts), [Cohort, cohorts]);

    return (
        <Card
            variant="outlined"
            className="StudentNetworkEventAccessFields"
            data-testid="StudentNetworkEventAccessFields"
        >
            <InputLabel>Visible To</InputLabel>
            <CardContent>
                {!institutionEntries && 'Loading...'}
                {!expanded && institutionEntries && (
                    <AccessSummary expand={() => setExpanded(true)} institutionEntries={institutionEntries} />
                )}
                {expanded && institutionEntries && (
                    <FormFields collapse={() => setExpanded(false)} institutionEntries={institutionEntries} />
                )}
            </CardContent>
        </Card>
    );
}

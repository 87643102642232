import { useEffect } from 'react';
import { useFormContext, type Path } from 'FrontRoyalReactHookForm';
import {
    type EditStudentNetworkFormValues,
    type InstitutionEntry,
} from 'AdminEditStudentNetworkEvent/AdminEditStudentNetworkEvent.types';
import CheckboxThatUpdatesAccessRules from './CheckboxThatUpdatesAccessRules';

export default function InstitutionCheckbox({ institutionEntry }: { institutionEntry: InstitutionEntry }) {
    const { institutionId, institutionName, programTypes } = institutionEntry;
    const name = `accessRules.targetInstitutionIds[${institutionId}]` as Path<EditStudentNetworkFormValues>;
    const { watch, setFieldValue } = useFormContext<EditStudentNetworkFormValues>();
    const checked = watch(name);

    // When an institution checkbox is checked, there is no point in also selecting the programs or cohorts
    // within it. Here we uncheck the program checkboxes. In other places we disabled the program checkboxes
    // and unselect/disable the cohort options.
    useEffect(() => {
        if (checked) {
            programTypes.forEach(programType => {
                setFieldValue(`accessRules.targetProgramTypes.${programType}`, false);
            });
        }
    }, [checked, programTypes, setFieldValue]);

    return <CheckboxThatUpdatesAccessRules name={name} label={`All ${institutionName} Cohorts`} />;
}

/**
 * This component manages the import process for cohort events. Using a stepper
 * UI, we walk the user through 4 steps: selecting a file, reviewing the data,
 * creating events, and viewing the results.
 *
 * Each step lives in a sub-component:
 * - HandleCSV: Allows the user to select a CSV file (or files) and parses it into a list of records
 * - ReviewRecords: Allows the user to review the data, edit dates and published status, and select a target cohort
 * - CreateEvents: Submits the import request to the server and handles the response
 * - ViewResults: Displays the results of the import with a link to each event and a link to view all new events
 */

import { useState, useCallback, useRef, useEffect } from 'react';
import { Box, Dialog, DialogContent, Button, Paper, CircularProgress } from '@mui/material';
import Oreo from 'Oreo';
import { type CohortForAdminListsAttrs } from 'Cohorts';
import { type AdminStudentNetworkEvent } from 'StudentNetworkEvent';
import { QuoralStepper } from 'FrontRoyalMaterialUi';
import { type ImportedCSVFileState, theme } from 'FrontRoyalMaterialUiForm';
import { EditCalendar, Plagiarism, PostAdd, Preview } from '@mui/icons-material';
import {
    type PortedStudentNetworkEvent,
    type ImportData,
    type ImportButtonRef,
    type ImportHandlerSteps,
} from '../AdminCohortEventsPorter.types';
import HandleCSV from './HandleCSV';
import ReviewRecords from './ReviewRecords';
import CreateEvents from './CreateEvents';
import ViewResults from './ViewResults';
import { mapEventRecords } from './importUtils';

interface ImportHandlerProps {
    open: boolean;
    onClose: () => void;
    cohorts: CohortForAdminListsAttrs[];
}

const steps = {
    HandleCSV: {
        label: 'Select File',
        description: 'Select a CSV file containing event data',
    },
    ReviewRecords: {
        label: 'Review Records',
        description: 'Review the event data before importing',
    },
    CreateEvents: {
        label: 'Create Events',
        description: 'Creating events on the server...',
    },
    ViewResults: {
        label: 'View Results',
        description: 'Success! Your events have been imported',
    },
} as ImportHandlerSteps;

const icons = {
    1: <PostAdd />, // HandleCSV
    2: <Plagiarism />, // ReviewRecords
    3: <EditCalendar />, // CreateEvents
    4: <Preview />, // ViewResults
};

const ImportHandler: React.FC<ImportHandlerProps> = ({ open, onClose, cohorts }) => {
    const [activeStep, setActiveStep] = useState<keyof typeof steps>('HandleCSV');
    const [isReviewDisabled, setIsReviewDisabled] = useState(true);
    const [isMapping, setIsMapping] = useState(false);
    const [processedFiles, setProcessedFiles] = useState<ImportedCSVFileState[]>([]);
    const [recordsMappedFromCsv, setRecordsMappedFromCsv] = useState<PortedStudentNetworkEvent[]>([]);
    const [importRequestData, setImportRequestData] = useState<ImportData | null>(null);
    const [importFailed, setImportFailed] = useState(false);
    const [importedEvents, setImportedEvents] = useState<AdminStudentNetworkEvent[]>([]);
    const importButtonRef = useRef<ImportButtonRef>({
        setDisabled: () => null,
        setOnClick: () => null,
    });

    const handleFilesProcessed = useCallback((files: ImportedCSVFileState[]) => {
        setProcessedFiles(files);
    }, []);

    // We have to use this convoluted approach if we want to show a spinner while
    // the data is being mapped due to the way React batches state updates.
    const activateReviewStep = () => {
        setIsMapping(true);
    };

    useEffect(() => {
        if (isMapping && activeStep === 'HandleCSV') {
            const processNextStep = async () => {
                // Straight up hack to make the spinner show up
                await new Promise(resolve => {
                    setTimeout(resolve, 200);
                });
                const successfulFiles = processedFiles.filter(f => f.status === 'success');
                const allRows = successfulFiles.flatMap(f => f.rows);
                const mappedRecords = mapEventRecords(allRows) as PortedStudentNetworkEvent[];
                setRecordsMappedFromCsv(mappedRecords);
                setActiveStep('ReviewRecords');
                setIsMapping(false);
                setIsReviewDisabled(false);
            };
            processNextStep();
        }
    }, [isMapping, activeStep, processedFiles]);

    const handleBack = useCallback(() => {
        setActiveStep(prevStep => {
            const stepIndex = Object.keys(steps).indexOf(prevStep);
            return Object.keys(steps)[stepIndex - 1] as keyof typeof steps;
        });
    }, []);

    const handleCancel = useCallback(
        (close = true) => {
            setProcessedFiles([]);
            setRecordsMappedFromCsv([]);
            setImportRequestData(null);
            setImportFailed(false);
            setImportedEvents([]);
            setActiveStep('HandleCSV');
            if (close) {
                onClose();
            }
        },
        [onClose],
    );

    const handleReviewSubmit = useCallback((reviewedImportData: ImportData) => {
        setImportRequestData(reviewedImportData);
        setActiveStep('CreateEvents');
    }, []);

    const handleImportComplete = useCallback((newEvents: AdminStudentNetworkEvent[]) => {
        setImportedEvents(newEvents);
        setActiveStep('ViewResults');
    }, []);

    const renderStep = () => {
        switch (activeStep) {
            case 'HandleCSV':
                return (
                    <HandleCSV
                        title={steps.HandleCSV.description || ''}
                        onFilesProcessed={handleFilesProcessed}
                        initialFiles={processedFiles}
                    />
                );
            case 'ReviewRecords':
                return (
                    <ReviewRecords
                        title={steps.ReviewRecords.description || ''}
                        recordsToImport={recordsMappedFromCsv}
                        cohorts={cohorts}
                        onConfirmImport={handleReviewSubmit}
                        importButtonRef={importButtonRef}
                    />
                );
            case 'CreateEvents':
                return (
                    <CreateEvents
                        title={steps.CreateEvents.description || ''}
                        importRequestData={importRequestData}
                        onImportComplete={handleImportComplete}
                        onImportFailed={() => setImportFailed(true)}
                        onBack={handleBack}
                    />
                );
            case 'ViewResults':
                return <ViewResults title={steps.ViewResults.description || ''} importedEvents={importedEvents} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (
            !processedFiles.length ||
            processedFiles.some(f => f.status === 'processing') ||
            !processedFiles.some(f => f.rows.length > 0)
        ) {
            setIsReviewDisabled(true);
        } else {
            setIsReviewDisabled(false);
        }
    }, [processedFiles]);

    const renderActionButtons = () => {
        switch (activeStep) {
            case 'HandleCSV':
                return (
                    <>
                        <Button variant="text" color="secondary" onClick={() => handleCancel(true)} sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={activateReviewStep}
                            disabled={isMapping || isReviewDisabled}
                        >
                            <Box display="flex" alignItems="center">
                                {isMapping && (
                                    <CircularProgress
                                        size={24}
                                        color="inherit"
                                        sx={{
                                            position: 'absolute',
                                            zIndex: 1,
                                            pointerEvents: 'none',
                                            inset: '0',
                                            margin: 'auto',
                                        }}
                                    />
                                )}
                                <Box sx={{ opacity: isMapping ? '0' : '1' }}>Review Records</Box>
                            </Box>
                        </Button>
                    </>
                );
            case 'ReviewRecords':
                return (
                    <>
                        <Button variant="text" onClick={() => handleBack()} sx={{ mr: 2 }}>
                            Back
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => importButtonRef.current.setOnClick(() => null)}
                            ref={(node: HTMLButtonElement | null) => {
                                if (node) {
                                    importButtonRef.current = {
                                        setDisabled: (disabled: boolean) => {
                                            node.disabled = disabled;
                                            node.classList.toggle('Mui-disabled', disabled);
                                        },
                                        setOnClick: (onClick: () => void) => {
                                            node.onclick = onClick;
                                        },
                                    };
                                }
                            }}
                        >
                            Create Events
                        </Button>
                    </>
                );
            case 'CreateEvents':
                return (
                    <>
                        {importFailed && (
                            <Button variant="text" onClick={() => handleBack()}>
                                Back
                            </Button>
                        )}
                    </>
                );
            case 'ViewResults':
                return (
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Button variant="text" color="secondary" onClick={() => handleCancel(false)}>
                            Start Over
                        </Button>
                        <Button variant="contained" color="primary" onClick={onClose}>
                            Finish
                        </Button>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            sx={{ backdropFilter: 'blur(6px)' }}
            PaperProps={{
                sx: {
                    bgcolor: theme.palette.background.default,
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: 4,
                },
            }}
            data-testid="import-handler"
        >
            <Paper
                square
                sx={{
                    m: 0,
                    p: 2,
                    backgroundColor: Oreo.COLOR_V3_BEIGE_LIGHTEST,
                    borderBottom: `1px solid ${Oreo.COLOR_V3_BEIGE_MIDDARK}`,
                }}
            >
                <QuoralStepper
                    activeStep={activeStep}
                    steps={steps}
                    icons={icons}
                    sx={{
                        maxWidth: theme.breakpoints.values.sm,
                        margin: '0 auto',
                    }}
                />
            </Paper>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', m: 0, p: 0 }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'auto',
                        m: 0,
                    }}
                >
                    <Paper square sx={{ p: 3 }}>
                        {renderStep()}
                    </Paper>
                </Box>
                {renderActionButtons() && (
                    <Paper
                        sx={{
                            p: 3,
                            m: 0,
                            backgroundColor: Oreo.COLOR_V3_BEIGE_LIGHTEST,
                            borderTop: `1px solid ${Oreo.COLOR_V3_BEIGE_MIDDARK}`,
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>{renderActionButtons()}</Box>
                    </Paper>
                )}
            </DialogContent>
        </Dialog>
    );
};
export default ImportHandler;

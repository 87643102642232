import { type ReactNode, memo, useState, useContext, useEffect } from 'react';
import { useAngularContext, AngularContext } from 'AngularContext';
import { angularDirectiveToReact } from 'Angular2reactHelper';
import { fromForm } from 'StudentNetworkEvent';
import { type EditStudentNetworkFormValues } from './AdminEditStudentNetworkEvent.types';

const StudentNetworkEventDetails = angularDirectiveToReact('FrontRoyal.Admin', 'studentNetworkEventDetails', {
    event: '<',
    containerSelector: '<?',
    fadeInImage: '<?',
});

const StudentNetworkEventListItem = angularDirectiveToReact('FrontRoyal.Admin', 'studentNetworkEventListItem', {
    event: '<',
});

function ShadowBox({ children }: { children: ReactNode }) {
    return (
        <div
            style={{
                boxShadow: '2px 3px 9px 0px rgba(0,0,0,0.75)',
                borderRadius: '5px',
                margin: '0px 12px 12px',
                overflow: 'hidden',
            }}
        >
            {children}
        </div>
    );
}

const Preview = ({ studentNetworkEvent }: { studentNetworkEvent: unknown }) =>
    studentNetworkEvent ? (
        <>
            <ShadowBox>
                <StudentNetworkEventListItem event={studentNetworkEvent} />
            </ShadowBox>
            <ShadowBox>
                <StudentNetworkEventDetails event={studentNetworkEvent} fadeInImage={false} />
            </ShadowBox>
        </>
    ) : null;

const MemoizedPreview = memo(
    Preview,
    (prevProps, nextProps) => prevProps.studentNetworkEvent === nextProps.studentNetworkEvent,
);

export default function StudentNetworkEventPreview({ formValues }: { formValues: EditStudentNetworkFormValues }) {
    // @ts-expect-error we don't have a good way to type this yet
    const { get } = useContext(AngularContext);
    const StudentNetworkEvent = get('StudentNetworkEvent');
    const $timeout = get('$timeout');
    const [studentNetworkEvent, setStudentNetworkEvent] = useState();
    const injector = useAngularContext();

    useEffect(() => {
        const promise = $timeout(async () => {
            const convertedValues = await fromForm(formValues, { injector });
            const event = studentNetworkEvent || StudentNetworkEvent.new();
            event.copyAttrs(convertedValues);

            // FIXME: it kind of sucks that we have to do this explicitly here.
            // Maybe we could have two different `fromForm` definitions, one
            // for converting for the api and one for converting for preview.
            event.image = {
                formats: {
                    original: {
                        url: formValues.image.getUrl && formValues.image.getUrl(),
                    },
                },
            };
            setStudentNetworkEvent(event);
        }, 500);

        return () => $timeout.cancel(promise);
    }, [$timeout, StudentNetworkEvent, formValues, studentNetworkEvent, injector]);

    return <MemoizedPreview studentNetworkEvent={studentNetworkEvent} />;
}

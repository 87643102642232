import angularModule from 'AdminGroups/angularModule/scripts/admin_groups_module';
import cacheAngularTemplate from 'cacheAngularTemplate';
import template from 'AdminGroups/angularModule/views/admin_edit_group.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

const adminEditGroupComponent = {
    templateUrl,
    bindings: {
        record: '<',
        goBack: '<',
        onRecordUpdated: '<',
        onRecordDeleted: '<',
        getBlankRecord: '<',
        setRecordId: '<',
    },
    controller: [
        '$scope',
        '$location',
        'Group',
        'AdminEditService',
        function Controller(scope, _location, Group, AdminEditService) {
            this.$onInit = () => {
                scope.thing = this.record;
                scope.goBack = this.goBack;
                // this isn't entirely straightforward, as the onRecordUpdated function in this context is
                // actually only being called on creation of a new record, and not when an existing record gets updated.
                scope.created = this.onRecordUpdated;
                scope.destroyed = this.onRecordDeleted;

                AdminEditService.onLink(scope, Group);

                scope.duplicate = () => {
                    const originalThing = scope.thing;

                    scope.thing = this.getBlankRecord();
                    scope.thing.name = originalThing.name;
                    scope.thing.stream_locale_pack_ids = originalThing.stream_locale_pack_ids;

                    scope.isNew = true;
                    this.setRecordId('create');
                };
            };
        },
    ],
    controllerAs: '$ctrl',
};

angularModule.component('adminEditGroupComponent', adminEditGroupComponent);

export default adminEditGroupComponent;

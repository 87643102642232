import { type ReactNode, useEffect, useState } from 'react';
import { useAngularContext } from 'AngularContext';
import { useFormContext, FormProvider } from 'FrontRoyalReactHookForm';
import {
    Autocomplete,
    InputAdornment,
    IconButton,
    Button,
    Paper,
    type ButtonProps,
    Tooltip,
    Link,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { FileCopy, InfoOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash/fp';
import copy from 'clipboard-copy';
import moment from 'moment-timezone';
import { useSyncConfig } from 'FrontRoyalConfig';
import { useRecordFromAdminTable, BackButton } from 'AdminTable';
import {
    toForm,
    fromForm,
    validationSchema,
    EVENT_TYPE_CONFIGS,
    EVENT_TYPE_CONFIGS_MAP,
    mappable,
    type StudentStatus,
} from 'StudentNetworkEvent';
import { Flex } from 'FrontRoyalMaterialUi';
import {
    DatePicker,
    TextField,
    SingleLocationInput,
    Checkbox,
    Select,
    FileInput,
    theme,
} from 'FrontRoyalMaterialUiForm';
import StudentNetworkEventPreview from './StudentNetworkEventPreview';
import StudentNetworkEventAccessFields from './StudentNetworkEventAccessFields';
import { type EditStudentNetworkFormValues, type FormFieldsProps } from './AdminEditStudentNetworkEvent.types';

interface HostOption {
    inputValue?: string;
    label: string;
}

const filterHostOptions = createFilterOptions<HostOption>();

const hostOptions: HostOption[] = [
    { label: 'Grace Erdmann' },
    { label: 'Emily Morrow' },
    { label: 'Lisa Thomson' },
    { label: 'Emily Straker-Barak' },
    { label: 'Jen Bonk' },
    { label: 'Kristina Batiste' },
    { label: 'Robert Steele' },
    { label: 'Darshan Desai' },
    { label: 'Natalie Sappleton' },
    { label: 'Sundar' },
    { label: 'Eleonora Carr' },
    { label: 'Amos Olujide' },
    { label: 'Harsh Mishra' },
    { label: 'Michael Sahloul' },
    { label: 'Maggie Cutler' },
    { label: 'Paul Lacourbe' },
    { label: 'Sara Alshareef' },
];

const rsvpOptions = [
    { key: 'not_required', label: 'RSVP Not Required' },
    { key: 'required', label: 'RSVP Required' },
    { key: 'closed', label: 'RSVP Closed' },
    { key: 'coming_soon', label: 'RSVP Coming Soon' },
];

type StudentStatusOption = {
    key: StudentStatus;
    label: string;
};
const studentStatusOptions: StudentStatusOption[] = [
    { key: 'prospect', label: 'Prospective Students' },
    { key: 'included_and_activated', label: 'Registered Students (before cohort start)' },
    { key: 'current', label: 'Current Students' },
    { key: 'graduated', label: 'Alumni' },
];

const FlexChild = ({ children }: { children: ReactNode }) => (
    <div style={{ flex: '1', minWidth: '200px', paddingBottom: '12px', margin: '0 8px' }}>{children}</div>
);

const FlexButton = ({ children, style, ...rest }: ButtonProps) => (
    <Button
        variant="contained"
        style={{ flex: '0 1 0', margin: `0 ${theme.spacing()}`, ...style }}
        type="submit"
        {...rest}
    >
        {children}
    </Button>
);

function LineBreak() {
    return <div style={{ flex: '0 0 100%' }} />;
}

function useSetTimezoneOnLocationChange(isChina: boolean) {
    const { watch, setFieldValue } = useFormContext<EditStudentNetworkFormValues>();
    const values = watch();
    const [latLng, setlatLng] = useState([
        values.place && values.place.details.lat,
        values.place && values.place.details.lng,
    ]);
    const config = useSyncConfig();

    useEffect(() => {
        const placeDetails = values.place && values.place.details;
        if (!placeDetails || !config) {
            return;
        }

        const newLatLng = [placeDetails.lat, placeDetails.lng];
        if (!isEqual(newLatLng, latLng)) {
            setlatLng([placeDetails.lat, placeDetails.lng]);

            if (isChina) {
                // use +08:00 for all China users
                setFieldValue('timezone', 'Asia/Shanghai');
                return;
            }

            const time = values.start_time || moment();
            const apiKey = config.google_api_key;

            const url = `https://maps.googleapis.com/maps/api/timezone/json?key=${apiKey}
            &location=${newLatLng.join(',')}&timestamp=${time.toDate().getTime() / 1000}`;

            setFieldValue('timezone', '');

            fetch(url)
                .then(response => response.json())
                .then(data => {
                    setFieldValue('timezone', data.timeZoneId);
                });
        }
    }, [config, latLng, isChina, values.place, values.start_time, setFieldValue]);
}

const EventStartPicker = ({ values, timezone }: { values: EditStudentNetworkFormValues; timezone: string }) => (
    <FlexChild>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <DatePicker name="start_time" label={values.date_tbd ? 'Shadow Start' : 'Start'} timezone={timezone} />
            {values.date_tbd && (
                <Tooltip
                    title={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <span>
                            This date is only used for sorting events in the <strong>DATE TBD</strong> section of the{' '}
                            <Link href="/student-network?show-events=true" target="_blank" color="inherit">
                                Events
                            </Link>{' '}
                            tab on the Student Network page. It is optional and only visible here.
                        </span>
                    }
                    placement="left"
                >
                    <InfoOutlined style={{ marginLeft: '8px', color: theme.palette.info.main }} />
                </Tooltip>
            )}
        </div>
    </FlexChild>
);

function FormFields({ destroy, duplicate, isChina }: FormFieldsProps) {
    useSetTimezoneOnLocationChange(isChina);
    const { t } = useTranslation('back_royal');
    const { watch, setFieldValue, formState, trigger } = useFormContext<EditStudentNetworkFormValues>();
    const values = watch();
    const { errors, isValid, isSubmitting, dirtyFields } = formState;
    const imageFieldIsDirty = !!dirtyFields.image;

    const formattedErrors = Object.entries(errors);

    useEffect(() => {
        const startTime = values.start_time;
        const endTime = values.end_time;

        if (startTime >= endTime) {
            setFieldValue('end_time', startTime.clone().add(1, 'hours'));
        }
    }, [setFieldValue, values.start_time, values.end_time]);

    // We have some validations where the validity of one field is dependent
    // on the state of another. In order to make sure all the validations stay
    // up to date, we have to trigger whenever a field changes that another field
    // depends on.
    useEffect(() => {
        trigger();
    }, [values.rsvp_status, values.external_rsvp_url, values.date_tbd, values.event_type, values.published, trigger]);

    // There are certain event types that we should automatically set as `featured = true`.
    // But if it is already marked as featured, we are not going to set featured = false if you change
    // the event type to something else.
    useEffect(() => {
        if (!values.featured) {
            const automaticFeaturedEventTypes = ['conference', 'special_event'];

            setFieldValue('featured', automaticFeaturedEventTypes.includes(values.event_type));
        }
        // we can't add values.featured to the dep array, because it will automatically trigger this effect again
        // and set featured back to true
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.event_type, setFieldValue]);

    useEffect(() => {
        const id = values.id;
        const published = values.published;

        if (id && published) {
            setFieldValue('shareable_url', `${window.ENDPOINT_ROOT}/student-network?event-id=${id}`);
        }
    }, [values.id, values.published, setFieldValue]);

    // If we select a new image, we need to clear the existing image_id, otherwise the server won't
    // process the new file; see student_network_events_controller.rb#save_image_asset
    useEffect(() => {
        if (imageFieldIsDirty && !!values.image_id) {
            setFieldValue('image_id', null);
        }
    }, [imageFieldIsDirty, values.image_id, setFieldValue]);

    const eventTypeConfig = EVENT_TYPE_CONFIGS_MAP[values.event_type];
    const showAttendanceTrackingFields = eventTypeConfig?.supportsAttendanceTracking && values.published;
    const isMappable = mappable(values);
    const timezone = isMappable ? values.timezone || moment.tz.guess() : moment.tz.guess();

    return (
        <Flex
            flexWrap="wrap"
            alignItems="flex-start"
            alignContent="flex-start"
            style={{
                maxWidth: '680px',
                padding: '12px 0px',
            }}
        >
            <FlexChild>
                <FileInput name="image" label="Upload Image" />
            </FlexChild>

            <LineBreak />

            <FlexChild>
                <TextField name="title" label="Title" fullWidth />
            </FlexChild>

            <FlexChild>
                <Select
                    name="event_type"
                    label="Type"
                    fullWidth
                    options={EVENT_TYPE_CONFIGS}
                    optionValue={opt => opt.key}
                    optionLabel={opt => t(`student_network.student_network_event.${opt.key}`)}
                />
            </FlexChild>

            {isMappable && (
                <>
                    <LineBreak />

                    <FlexChild>
                        <SingleLocationInput name="place" label="Location" placeType="address" />
                    </FlexChild>

                    <FlexChild>
                        <TextField name="location_name" label="Location Name" />
                    </FlexChild>
                </>
            )}

            <LineBreak />

            <FlexChild>
                <Checkbox name="date_tbd" label="Exact Date TBD" />
            </FlexChild>

            {values.date_tbd && (
                <FlexChild>
                    <TextField name="date_tbd_description" label="Date Description" fullWidth />
                </FlexChild>
            )}

            <EventStartPicker values={values} timezone={timezone} />

            {!values.date_tbd && (
                <FlexChild>
                    <DatePicker name="end_time" label="End" timezone={timezone} />
                </FlexChild>
            )}

            <LineBreak />

            <FlexChild>
                <TextField name="description" label="Description (Markdown)" multiline rows={3} fullWidth />
            </FlexChild>

            <LineBreak />

            <FlexChild>
                <Select
                    name="rsvp_status"
                    label="RSVP Options"
                    fullWidth
                    options={rsvpOptions}
                    optionValue={opt => opt.key}
                    optionLabel={opt => opt.label}
                />
            </FlexChild>

            <FlexChild>
                <TextField name="external_rsvp_url" label="External RSVP URL" fullWidth />
            </FlexChild>

            <LineBreak />

            {showAttendanceTrackingFields && (
                <>
                    <FlexChild>
                        <Autocomplete
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            fullWidth
                            value={values.host}
                            options={hostOptions}
                            onChange={(_event, newValue) => {
                                if (typeof newValue === 'string') {
                                    setFieldValue('host', newValue);
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    setFieldValue('host', newValue.inputValue);
                                } else {
                                    setFieldValue('host', newValue?.label || null);
                                }
                            }}
                            getOptionLabel={option => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.label;
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filterHostOptions(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some(option => inputValue === option.label);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        label: `Add "${inputValue}"`,
                                    });
                                }

                                return filtered;
                            }}
                            renderOption={(props, option) => {
                                const { ...optionProps } = props;
                                return <li {...optionProps}>{option.label}</li>;
                            }}
                            renderInput={props => <TextField {...props} name="host" label="Host" fullWidth />}
                        />
                    </FlexChild>

                    <FlexChild>
                        <TextField name="rsvp_count" label="RSVP Count" type="number" fullWidth />
                    </FlexChild>

                    <FlexChild>
                        <TextField name="attendance_count" label="Attendance Count" type="number" fullWidth />
                    </FlexChild>

                    <LineBreak />
                </>
            )}

            <FlexChild>
                <TextField name="internal_notes" label="Internal Notes" multiline rows={3} fullWidth />
            </FlexChild>

            <LineBreak />

            <FlexChild>
                <Select
                    name="target_student_statuses"
                    label="Invitees"
                    fullWidth
                    multiple
                    options={studentStatusOptions}
                    optionValue={opt => opt.key}
                    optionLabel={opt => opt.label}
                />
            </FlexChild>

            <LineBreak />

            <FlexChild>
                <StudentNetworkEventAccessFields />
            </FlexChild>

            <LineBreak />

            <FlexChild>
                <Checkbox name="published" label="Published" />
                <Checkbox name="featured" label="Featured" />
            </FlexChild>

            {values.shareable_url && (
                <>
                    <LineBreak />

                    <FlexChild>
                        <TextField
                            name="shareable_url"
                            label="Shareable URL"
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Copy"
                                            onClick={() => {
                                                copy(values.shareable_url);
                                            }}
                                        >
                                            <FileCopy />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FlexChild>
                </>
            )}

            <LineBreak />

            <Flex justifyContent="space-between" flexGrow="1">
                <FlexButton
                    type="button"
                    name="duplicate"
                    color="neutral"
                    onClick={() => {
                        duplicate();
                        setFieldValue('published', false);
                        setFieldValue('title', `Duplicate of ${values.title}`);
                    }}
                    disabled={isSubmitting || !values.id}
                    style={{ flexBasis: 'auto' }}
                >
                    Duplicate
                </FlexButton>

                <FlexButton
                    type="button"
                    name="destroy"
                    color="secondary"
                    onClick={destroy}
                    style={{ marginLeft: 'auto' }}
                    disabled={isSubmitting || !values.id}
                >
                    Delete
                </FlexButton>

                <FlexButton type="submit" color="primary" disabled={!isValid || isSubmitting}>
                    Save
                </FlexButton>
            </Flex>

            <LineBreak />

            {formattedErrors.length > 0 && (
                <Paper
                    style={{
                        padding: '12px 8px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: 20,
                        color: 'red',
                    }}
                >
                    Please correct the following errors:
                    <br />
                    <ul style={{ marginBottom: 0 }}>
                        {formattedErrors.map(([key, value]) => (
                            <li key={key}>{value.message}</li>
                        ))}
                    </ul>
                </Paper>
            )}
        </Flex>
    );
}

export default function AdminEditStudentNetworkEvent() {
    const $injector = useAngularContext();
    const ConfigFactory = $injector.get('ConfigFactory') as {
        getSync: (arg: boolean) => { chinaRegionMode: () => boolean };
    };
    const chinaRegionMode = ConfigFactory.getSync(true).chinaRegionMode();

    const formResources = useRecordFromAdminTable({
        fromForm,
        toForm,
        validationSchema,
    });

    if (!formResources) {
        return <>Loading...</>;
    }

    const {
        formFunctions,
        formFunctions: { watch, handleSubmit },
        onSubmit,
        destroy,
        duplicate,
    } = formResources;

    const values = watch() as EditStudentNetworkFormValues;

    return (
        <div className="admin-edit-student-network-event">
            <FormProvider {...formFunctions}>
                <BackButton home="Events" detail={values.title || 'New Event'} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '360px' }}>
                            <StudentNetworkEventPreview formValues={values} />
                        </div>
                        <FormFields destroy={destroy} duplicate={duplicate} isChina={chinaRegionMode} />
                    </div>
                </form>
            </FormProvider>
        </div>
    );
}
